<div class="modal-header">
  <h4 class="modal-title ml-2" id="modal-basic-title">
    <span *ngIf="type === 'edit'"
      >Edit Prizes Record - {{ prizesLocal.name }}</span
    >
    <span *ngIf="type === 'new'">New Prizes Record</span>
  </h4>
</div>
<div class="modal-body">
  <div class="row firstRow">
    <div class="col-3">
      <label for="editName">Code:</label>
    </div>
    <div class="col-6">
      <label for="editName">Name:</label>
    </div>
    <div class="col-3">
      <label for="editPrizes">Prize:</label>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <input
        type="text"
        id="editCode"
        [(ngModel)]="prizesLocal.code"
        class="edit"
      />
    </div>
    <div class="col-6">
      <input
        type="text"
        id="editName"
        [(ngModel)]="prizesLocal.name"
        class="edit"
      />
    </div>
    <div class="col-3">
      <input
        type="text"
        id="editPrize"
        [(ngModel)]="prizesLocal.prize"
        class="edit"
      />
    </div>
  </div>
  <div class="row secondRow">
    <div class="col-6">
      <label for="editType">Type (0 - $, 1 - Points):</label>
    </div>
    <div class="col-6">
      <label for="editOption">Prize Option (shared/split):</label>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <input
        type="text"
        id="editType"
        [(ngModel)]="prizesLocal.type"
        class="edit"
      />
    </div>
    <div class="col-6">
      <input
        type="text"
        id="editOption"
        [(ngModel)]="prizesLocal.option"
        class="edit"
      />
    </div>
  </div>
  <div class="row secondRow">
    <div class="col-12">
      <label for="editDescription">Description:</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12 edit">
      <input
        type="text"
        id="editDescription"
        [(ngModel)]="prizesLocal.description"
        class="edit"
      />
    </div>
  </div>
</div>
<div class="row footer" *ngIf="!deleting">
  <div class="col-6 text-left">
    <button
      type="button"
      class="btn btn-danger delete"
      (click)="deleting = true"
    >
      Delete
    </button>
  </div>
  <div class="col-6 text-right">
    <button
      type="button"
      class="btn btn-default cancel"
      (click)="cancelDialog()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success submit"
      [disabled]="!changesMade()"
      (click)="updatePrizes()"
    >
      <i *ngIf="spinning" class="fas fa-spinner fa-spin"></i>&nbsp;Submit
    </button>
  </div>
</div>
<div class="row footer" *ngIf="deleting">
  <div class="col-5 text-right">Are you sure?</div>
  <div class="col-1">
    <button
      type="button"
      class="btn btn-danger delete"
      (click)="deletePrizes(prizesLocal.id)"
    >
      Yes
    </button>
  </div>
  <div class="col-6 text-left">
    <button
      type="button"
      class="btn btn-default cancel"
      (click)="deleting = false"
    >
      No
    </button>
  </div>
</div>
