import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
})
export class CheckboxComponent {
  @Input()
  label: string;

  @Input()
  name: string;

  @Input()
  checked: boolean;

  @Output()
  checkUpdated: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  checkboxClicked() {
    this.checked = !this.checked;
    this.checkUpdated.emit(this.checked);
  }
}
