import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*',
  },
});

@Injectable({
  providedIn: 'root',
})
export class WikiSearchService {
  private _url: string;
  private _options: any;

  constructor(private http: HttpClient) {
    this._url = 'https://en.wikipedia.org/w/api.php';
  }

  search(search: string) {
    if (search === '') {
      return of([]);
    }

    // need to fix
    return this.http
      .get(this._url, { params: PARAMS.set('search', search) })
      .pipe(map((response) => response));
  }
}
