<div class="container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <p>
        We host all sorts of events, many of which are free! Check out our
        social media pages for upcoming events, as that is where we currently
        post the latest updates. Our current Facebook event calendar is listed
        below.
      </p>
      <p>
        Upcoming events include: <br />
        <li><a href="#" routerLink="/ghoulpool">Annual Ghoul Pool!</a></li>
        <li>Movie Nights every month (invite only)</li>
        <li>Table top horror gaming sessions</li>
        <li>Author, Artist and Actor signings</li>
        <li>Creepy photography sessions</li>
        <span style="margin-left: 60px">
          <a href="#" routerLink="/events/easter2024"
            >Peter Rottentail Easter Portraits 2024!</a
          >
        </span>
      </p>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-12 text-center bottom-gap">
      <div
        class="fb-page"
        data-href="https://www.facebook.com/ghoulishmortals/"
        data-tabs="events"
        data-width="600"
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="true"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://www.facebook.com/ghoulishmortals/"
          class="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/ghoulishmortals/"
            >Ghoulish Mortals</a
          >
        </blockquote>
      </div>
    </div>
  </div>
</div>
