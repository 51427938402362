<div class="contestantData">
  <div class="row underlineRow">
    <div class="col-3 text-center">Name</div>
    <div class="col-3 text-center">Email</div>
    <div class="col-3 text-center">Telephone</div>
    <div class="col-3 text-center">
      Entries ({{ competition.maxEntries }}/{{ competition.maxAlternates }})
    </div>
  </div>
  <div class="row">
    <div class="col-3 text-center">{{ contestant.name }}</div>
    <div class="col-3 text-center">
      <a
        [href]="getContestantEmailLink(contestant.email)"
        placement="top"
        ngbTooltip="Click this link to create a bookmarkable URL."
        >{{ contestant.email }}</a
      >
    </div>
    <div class="col-3 text-center">{{ contestant.telephone }}</div>
    <div class="col-3 text-center">{{ entryCounts }}</div>
  </div>
</div>
