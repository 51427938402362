export class Prizes {
  constructor(
    public id: number,
    public code: string,
    public name: string,
    public description: string,
    public prize: number,
    public type: number,
    public option: string
  ) {}
}

export class PrizesCreateDTO {
  constructor(
    public code: string,
    public name: string,
    public description: string,
    public prize: number,
    public type: number,
    public option: string
  ) {}
}

export class PrizesUpdateDTO {
  constructor(
    public code: string,
    public name: string,
    public description: string,
    public prize: number,
    public type: number,
    public option: string
  ) {}
}

export class PrizesDisplay {
  constructor(
    public id: number,
    public idx: number,
    public code: string,
    public name: string,
    public description: string,
    public prize: number,
    public type: number,
    public option: string
  ) {}
}
