import { Routes } from '@angular/router';

import { AboutComponent } from './pages/about/about.component';
import { ArtistsComponent } from './pages/artists/artists.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ReturnsComponent } from './pages/returns/returns.component';
import { TermsComponent } from './pages/terms/terms.component';
import { VersionComponent } from './pages/version/version.component';
import { EventsComponent } from './pages/events/events.component';
import { Easter2024Component } from './pages/events/easter2024/easter2024.component';
import { EasterHelpComponent } from './pages/events/easter-help/easter-help.component';
import { Easter2020Component } from './pages/events/easter2020/easter2020.component';
import { Easter2022Component } from './pages/events/easter2022/easter2022.component';
import { Easter2023Component } from './pages/events/easter2023/easter2023.component';
import { GhoulpoolComponent } from './ghoulpool/pages/ghoulpool/ghoulpool.component';
import { Ghoulpool2019Component } from './ghoulpool/pages/ghoulpool2019/ghoulpool2019.component';
import { Ghoulpool2020Component } from './ghoulpool/pages/ghoulpool2020/ghoulpool2020.component';
import { Ghoulpool2021Component } from './ghoulpool/pages/ghoulpool2021/ghoulpool2021.component';
import { Ghoulpool2022Component } from './ghoulpool/pages/ghoulpool2022/ghoulpool2022.component';
import { Ghoulpool2023Component } from './ghoulpool/pages/ghoulpool2023/ghoulpool2023.component';
import { GhoulpoolEntryComponent } from './ghoulpool/entry/pages/ghoulpool-entry/ghoulpool-entry.component';
import { GhoulpoolAdminComponent } from './ghoulpool/admin/pages/ghoulpool-admin/ghoulpool-admin.component';
import { GhoulpoolResultsComponent } from './ghoulpool/pages/ghoulpool-results/ghoulpool-results.component';

export const routes: Routes = [
  { path: 'artists', component: ArtistsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'ghoulpool', component: GhoulpoolComponent },
  { path: 'ghoulpool2019', component: Ghoulpool2019Component },
  { path: 'ghoulpool2020', component: Ghoulpool2020Component },
  { path: 'ghoulpool2021', component: Ghoulpool2021Component },
  { path: 'ghoulpool2022', component: Ghoulpool2022Component },
  { path: 'ghoulpool2023', component: Ghoulpool2023Component },
  { path: 'ghoulpoolEntry', component: GhoulpoolEntryComponent },
  { path: 'ghoulpoolAdmin', component: GhoulpoolAdminComponent },
  { path: 'ghoulpoolResults', component: GhoulpoolResultsComponent },
  { path: 'events', component: EventsComponent },
  { path: 'events/easter-help', component: EasterHelpComponent },
  { path: 'events/easter2020', component: Easter2020Component },
  { path: 'events/easter2022', component: Easter2022Component },
  { path: 'events/easter2023', component: Easter2023Component },
  { path: 'events/easter2024', component: Easter2024Component },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'returns', component: ReturnsComponent },
  { path: 'version', component: VersionComponent },
  { path: '', component: HomePageComponent },
];
