<div class="container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <p>
        We moved from Southern CA in 2015, where we now realize there was
        clearly an over abundancy of cool retail horror stores. When we were
        done unpacking, we ventured out to explore the giant metropolis that is
        Chicago, and we were amazed that there weren’t any horror stores here...
        like at all. So, on a long trip to a horror con in St. Louis in 2016, we
        batted around the notion of opening our own store, more as a way to pass
        the time on the road. We effortlessly filled a spiral notebook with
        ideas for layout, décor, merchandise, party themes, unique events. From
        that point on, we talked about it in the theoretical sense "if we did
        the store, we would definitely have to have this or that". That went on
        for months and months. The store had become this creatively quirky
        inspiration that we somehow were always talking and texting about. On
        the next road trip to the same horror con the following year, we
        marveled at the notion that we had somehow signed a lease and that were
        taking a break from hammering away on building out the interior. After
        over a solid year of building out the store, we were on the road to that
        horror con again, this time as retail buyers intent on finding things to
        carry in the store. We opened a few weeks later, much to the delight of
        all the fellow monster/horror creeples that had been less and less
        patiently believing the sign in the window that promised “coming soon”.
        On June 29, 2018 we had our official grand opening ribbon ceremony...
        dressed as zombies so we could gnaw the ribbon apart of course.
      </p>
      <p>
        Ghoulish Mortals is here Chicago! Come check us out…because we built it
        for you, and uh, er... us.
      </p>
    </div>
    <div class="col-md-1"></div>
  </div>
  <div class="row">
    <div class="text-center">
      <iframe
        src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fghoulishmortals%2Fvideos%2F1975900889316070%2F&show_text=0&width=476"
        width="476"
        height="476"
        style="border: none; overflow: hidden"
        scrolling="no"
        frameborder="0"
        allowTransparency="true"
        allowFullScreen="true"
      ></iframe>
    </div>
  </div>
</div>
