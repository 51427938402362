<div class="container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <h1>Return Policy</h1>
      <strong>In Store Purchases</strong>
      <p>
        Items purchased in our retail store are returnable within 7 days, with a
        valid receipt, provided they are in a sellable condition. All items will
        be exchanged for store credit.
      </p>
      <strong>Website Purchases</strong>
      <p>
        Items purchased through the Ghoulish Mortals web page are exchangeable
        or returnable within 7 days of the date of delivery, provided they are
        received back in sellable condition. If the product was received
        damaged, then please keep packaging and email us, providing a picture of
        the damaged product and box so that we can take it up with the shipping
        company while we are processing your replacement. Due to the personal
        nature of any custom products using your image, they are not usually
        returnable unless there is a mistake or defect, but in which case we
        will work with you to redo the product. If absolutely need be, we will
        have you return the custom tattoo for credit towards a stock tattoo.
      </p>
      <p>
        If you would like to return or exchange a product, please email us at
        info&#64;ghoulishmortals.com with your info and concerns, and we will
        get back to you with solutions and give our address if needed.
      </p>
      <p>
        If it is decided that you would like to return a product, when you send
        it back, please use original packing materials, and make sure you retain
        the tracking number for your records. We do not reimburse for return
        shipping, and we are not responsible for returned items lost or damaged
        while shipping.
      </p>
      <p>
        A refund or customer credit (for exchanges) will be issued within 10
        business days. All refunds will be issued in the original form of
        payment. Usually it only takes Paypal a few days, but please allow up to
        two weeks for the credit to appear on your credit card statement.
      </p>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
