import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-easter2023',
  templateUrl: './easter2023.component.html',
  styleUrls: ['./easter2023.component.css'],
})
export class Easter2023Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
