<div class="row title">
  <div class="col-12 text-center">People Maintenance</div>
</div>
<div class="row searchLine">
  <div class="col-6 text-left">
    <label for="personSearch">Search :</label>
    <input
      id="personSearch"
      type="text"
      class="personSearch"
      (keyup)="filterData()"
      [(ngModel)]="personSearch"
      placeholder="People search"
    />
  </div>
  <div class="col-2">
    <app-checkbox
      label="New People"
      name="newPeopleCheckbox"
      [checked]="toggleNew"
      (checkUpdated)="setToggle(1, $event)"
    ></app-checkbox>
  </div>
  <div class="col-2">
    <app-checkbox
      label="Status Changes"
      name="statusChangeCheckbox"
      [checked]="toggleStatusChange"
      (checkUpdated)="setToggle(2, $event)"
    ></app-checkbox>
  </div>
  <div class="col-2">
    <button type="button" class="btn btn-success submit" (click)="addPerson()">
      Add Person
    </button>
  </div>
</div>
<div class="table-fixed-height">
  <table class="table table-striped" aria-describedby="People List">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col" sortable="name" (sort)="onSort($event)">Name (Age)</th>
        <th scope="col" sortable="knownas" (sort)="onSort($event)">Known As</th>
        <th scope="col" sortable="dateofdeath" (sort)="onSort($event)">
          Date of Death
        </th>
        <th scope="col" sortable="cause" (sort)="onSort($event)">Cause</th>
        <th scope="col" sortable="search" (sort)="onSort($event)">Search</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let person of peopleDisplay" class="onHover">
        <th scope="row" (click)="editPerson(person.id)">{{ person.id }}</th>
        <td (click)="editPerson(person.id)">
          {{ person.name }} ({{ person.age | number }})
        </td>
        <td (click)="editPerson(person.id)">{{ person.knownAs }}</td>
        <td (click)="editPerson(person.id)">{{ person.dateOfDeath | date }}</td>
        <td (click)="editPerson(person.id)">{{ person.causeOfDeath }}</td>
        <td (click)="editPerson(person.id)">{{ person.search }}</td>
        <td>
          <a *ngIf="person.wikiURL !== ''" [href]="person.wikiURL" target="_new"
            >Wiki</a
          >
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="row">
  <div class="d-flex justify-content-between p-2 ml-2">
    <ngb-pagination
      [collectionSize]="peopleFiltered.length"
      [(page)]="page"
      [pageSize]="pageSize"
      (pageChange)="refreshPeople()"
      [maxSize]="5"
      [rotate]="true"
      [ellipses]="false"
      [boundaryLinks]="true"
    >
    </ngb-pagination>
  </div>
</div>
<ng-template #adminEditPerson let-modal>
  <app-people-edit
    [person]="personSelected"
    [type]="type"
    (returnOption)="returnOption($event)"
    (returnPerson)="updatePeople($event)"
  ></app-people-edit>
</ng-template>
