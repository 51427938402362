export class Contestant {
    constructor(
        public id: number,
        public name: string,
        public email: string,
        public telephone: string
    ) {
    }
}

export class ContestantCreateDTO {
    constructor(
        public name: string,
        public email: string,
        public telephone: string
    ) {
    }
}

export class ContestantUpdateDTO {
    constructor(
        public name: string,
        public email: string,
        public telephone: string
    ) {
    }
}

export class CompetitionName {
    constructor(
        public name: string,
    ) {}
}
export class ContestantDisplay {
    constructor(
        public id: number,
        public idx: number,
        public name: string,
        public email: string,
        public telephone: string,
        public competitionList: Array<string>
    ) {
    }
}

export class ContestantInfo {
    constructor(
        public id: number,
        public name: string,
        public email: string,
        public telephone: string,
        public competitionList: string
    ) {
    }
}
