import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Competition } from '../../../../models/competition';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { GlobalService } from '../../../../services/global.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-view-competition',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './view-competition.component.html',
  styleUrl: './view-competition.component.scss',
})
export class ViewCompetitionComponent {
  @Input()
  competition: Competition;

  @Input()
  type: string;

  @Output()
  returnType: EventEmitter<string> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    public globalService: GlobalService,
    private router: Router
  ) {}

  closeView() {
    this.globalService.adminType = '';
    this.globalService.adminSelectionId = 0;
    this.setQueryParams();
  }

  editCompetition() {
    this.globalService.adminType = 'edit';
    this.setQueryParams();
  }

  setQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.globalService.adminQueryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}
