<div class="row searchLine">
  <div class="col-6 text-left">
    <label for="prizesSearch">Search :</label>
    <input
      id="prizesSearch"
      type="text"
      class="prizesSearch"
      (keyup)="filterData()"
      [(ngModel)]="prizesSearch"
      placeholder="Prizes search"
    />
  </div>
  <div class="col-4">&nbsp;</div>
  <div class="col-2">
    <button type="button" class="btn btn-success submit" (click)="addPrizes()">
      Add Prizes
    </button>
  </div>
</div>
<div class="table-fixed-height">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col" sortable="code" (sort)="onSort($event)">Code</th>
        <th scope="col" sortable="name" (sort)="onSort($event)">Name</th>
        <th scope="col" sortable="description" (sort)="onSort($event)">
          Description
        </th>
        <th
          scope="col"
          sortable="prizes"
          (sort)="onSort($event)"
          class="text-center"
        >
          Prizes
        </th>
        <th
          scope="col"
          sortable="prizes"
          (sort)="onSort($event)"
          class="text-center"
        >
          Type
        </th>
        <th
          scope="col"
          sortable="prizes"
          (sort)="onSort($event)"
          class="text-center"
        >
          Option
        </th>
      </tr>
    </thead>
    <tbody *ngIf="prizesDisplay">
      <tr *ngFor="let prize of prizesDisplay" class="onHover">
        <th scope="row" (click)="editPrizes(prize.id)">{{ prize.idx }}</th>
        <td (click)="editPrizes(prize.id)">{{ prize.code }}</td>
        <td (click)="editPrizes(prize.id)">{{ prize.name }}</td>
        <td (click)="editPrizes(prize.id)">{{ prize.description }}</td>
        <td (click)="editPrizes(prize.id)">{{ prize.prize | number }}</td>
        <td (click)="editPrizes(prize.id)">{{ prize.type | number }}</td>
        <td (click)="editPrizes(prize.id)">{{ prize.option }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="row">
  <div class="d-flex justify-content-between p-2 ml-2">
    <ngb-pagination
      [collectionSize]="prizesFiltered.length"
      [(page)]="page"
      [pageSize]="pageSize"
      (pageChange)="refreshPrizes()"
      [maxSize]="5"
      [rotate]="true"
      [ellipses]="false"
      [boundaryLinks]="true"
    >
    </ngb-pagination>
  </div>
</div>
<ng-template #adminEditPrizes let-modal>
  <app-view-competition-prizes-edit
    [prizes]="prizesSelected"
    [type]="type"
    (returnOption)="returnOption($event)"
    (returnPrizes)="updatePrizes($event)"
  ></app-view-competition-prizes-edit>
</ng-template>
