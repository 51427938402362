import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  Competition,
  CompetitionDisplay,
} from '../../../../models/competition';
import { Contestant } from '../../../../models/contestant';
import { EntriesDisplay } from '../../../../models/entries';
import { PeopleStatus, PeopleUpdateDTO } from '../../../../models/people';
import { CompetitionService } from '../../../../services/competition.service';
import { ContestantService } from '../../../../services/contestant.service';
import { EmailService } from '../../../../services/email.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PeopleService } from '../../../../services/people.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-ghoul-pool-view-entries',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule],
  templateUrl: './ghoul-pool-view-entries.component.html',
  styleUrl: './ghoul-pool-view-entries.component.scss',
})
export class GhoulPoolViewEntriesComponent implements OnInit, OnChanges {
  @Input()
  competition: Competition;

  @Input()
  contestant: Contestant;

  @Input()
  entries: Array<EntriesDisplay>;

  @Input()
  totalPoints: number;

  @Input()
  totalPrizes: number;

  @Output()
  deleteIdx: EventEmitter<number> = new EventEmitter();

  @Output()
  statusChange: EventEmitter<EntriesDisplay> = new EventEmitter();

  @ViewChild('markDeleted', { static: false }) private markDeleted = null;

  public todaysDate: Date;
  public selectPerson: EntriesDisplay;
  public competitionStatus: string;
  public competitionList: CompetitionDisplay[];

  ePeopleStatus = PeopleStatus;

  constructor(
    private activatedRoute: ActivatedRoute,
    private competitionService: CompetitionService,
    private contestantService: ContestantService,
    private emailService: EmailService,
    private modalService: NgbModal,
    private peopleService: PeopleService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.todaysDate = new Date();
    this.competitionStatus = this.competitionService.getCompetitionStatus(
      this.competition
    );
    this.getCompetitionListForContestant();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getCompetitionListForContestant();
  }

  getCompetitionListForContestant() {
    this.competitionService
      .getCompetitionsByContestant(this.contestant.id)
      .subscribe((results) => {
        this.competitionList = results.filter(
          (r) => r.code !== this.competition.code
        );
      });
  }

  reNavigate(c: CompetitionDisplay) {
    if (c.status !== 'Not Started') {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          email: this.contestant.email,
          code: c.code,
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }

  isEntryActive(): boolean {
    return this.todaysDate <= new Date(this.competition.entryEndDate);
  }

  deleteEntry(i: number) {
    this.deleteIdx.emit(i);
  }

  deadRequest(entry: EntriesDisplay) {
    this.selectPerson = entry;
    this.modalService.open(this.markDeleted, {
      centered: true,
      size: 'md',
      windowClass: 'dark-modal',
    });
  }

  deadRequestConfirm() {
    const peopleUpdate = new PeopleUpdateDTO(
      this.selectPerson.name,
      this.selectPerson.people.knownAs,
      this.selectPerson.people.dateOfBirth,
      this.selectPerson.people.dateOfDeath,
      this.selectPerson.people.causeOfDeath,
      this.selectPerson.people.wikiURL,
      this.selectPerson.people.search,
      PeopleStatus.PENDING,
      this.contestant.email
    );
    this.modalService.dismissAll();
    this.peopleService
      .update(this.selectPerson.people.id, peopleUpdate)
      .subscribe((result) => {
        this.emailService.send(
          environment.internalEmail,
          'A contestant of the Ghoul Pool has requested a status check on ' +
            this.selectPerson.people.name,
          'Status check for ' +
            this.selectPerson.people.name +
            '<br>' +
            'From email : ' +
            this.contestant.email,
          true
        );
        this.selectPerson.people.status = PeopleStatus.PENDING;
        this.selectPerson.people.statusChangeRequestedBy =
          this.contestant.email;
        this.statusChange.emit(this.selectPerson);
      });
  }

  splitNotes(notes: string): string {
    let returnString = '';
    for (const str of notes.split('|')) {
      returnString += returnString !== '' ? '<br>' : '';
      returnString += str;
    }
    return returnString;
  }
}
