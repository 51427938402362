import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  // only set within admin pages and causes the header to not be shown
  private _isAdmin = false;
  get isAdmin(): boolean {
    return this._isAdmin;
  }
  set isAdmin(value: boolean) {
    this._isAdmin = value;
  }

  // to enable bookmarks, the tab selected when in admin
  private _adminTab = 1;
  get adminTab(): number {
    return this._adminTab;
  }
  set adminTab(value: number) {
    this._adminTab = value;
  }

  // to enable bookmarks, the tab selected when in admin
  private _adminType = '';
  get adminType(): string {
    return this._adminType;
  }
  set adminType(value: string) {
    this._adminType = value;
  }

  // to enable bookmarks, the tab selected when in admin
  private _adminSelectionId: number;
  get adminSelectionId(): number {
    return this._adminSelectionId;
  }
  set adminSelectionId(value: number) {
    this._adminSelectionId = value;
  }

  // to enable bookmarks, the tab selected when in admin
  private _adminSubTab = 1;
  get adminSubTab(): number {
    return this._adminSubTab;
  }
  set adminSubTab(value: number) {
    this._adminSubTab = value;
  }

  // to enable bookmarks, the tab selected when in admin
  private _adminQueryParams: Params;
  get adminQueryParams(): Params {
    this._adminQueryParams = {
      tab: this._adminTab,
      type: this._adminType,
      selectionId: this._adminSelectionId,
      subtab: this._adminSubTab,
    };
    return this._adminQueryParams;
  }
  set adminQueryParams(value: Params) {
    this._adminQueryParams = value;
  }

  constructor() {
    this._isAdmin = false;
    this._adminTab = 1;
    this._adminType = '';
    this._adminSelectionId = 0;
    this._adminSubTab = 1;
  }
}
