<div class="modal-header">
  <h4 class="modal-title ml-2" id="modal-basic-title">
    <span *ngIf="type === 'edit'"
      >Edit Person Record - {{ personLocal.name }}</span
    >
    <span *ngIf="type === 'new'">New Person Record</span>
  </h4>
</div>
<div class="modal-body">
  <div class="row firstRow">
    <div class="col-4">
      <label for="editName">Name:</label>
    </div>
    <div class="col-4">
      <label for="editKnownAs">Known As:</label>
    </div>
    <div class="col-4">
      <label for="editSearch">Search:</label>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <input
        type="text"
        id="editName"
        [(ngModel)]="personLocal.name"
        class="edit"
      />
    </div>
    <div class="col-4">
      <input
        type="text"
        id="editKnownAs"
        [(ngModel)]="personLocal.knownAs"
        class="edit"
      />
    </div>
    <div class="col-4">
      <input
        type="text"
        id="editSearch"
        [(ngModel)]="personLocal.search"
        class="edit"
      />
    </div>
  </div>
  <div class="row secondRow">
    <div class="col-4">
      <label for="editDateOfBirth">Date of Birth (YYYY-MM-DD):</label>
    </div>
    <div class="col-4">
      <label for="editDateOfDeath">Date of Death (YYYY-MM-DD):</label>
    </div>
    <div class="col-4">
      <label for="editCauseOfDeath">Cause Of Death:</label>
    </div>
  </div>
  <div class="row">
    <div class="col-4 edit">
      <input
        type="text"
        id="editDateOfBirth"
        [(ngModel)]="dateOfBirth"
        ngbDatepicker
        #d="ngbDatepicker"
        class="w-80"
      />
      <button
        class="btn btn-outline-secondary"
        (click)="d.toggle()"
        type="button"
      >
        <i class="fa fa-calendar" aria-hidden="true"></i>
      </button>
    </div>
    <div class="col-4 edit">
      <input
        type="text"
        id="editDateOfDeath"
        [(ngModel)]="dateOfDeath"
        (change)="dodChange()"
        ngbDatepicker
        #d2="ngbDatepicker"
        class="w-80"
      />
      <button
        class="btn btn-outline-secondary"
        (click)="d2.toggle()"
        type="button"
      >
        <i class="fa fa-calendar" aria-hidden="true"></i>
      </button>
    </div>
    <div class="col-4 edit">
      <input
        type="text"
        id="editCauseOfDeath"
        [(ngModel)]="personLocal.causeOfDeath"
        class="w-100"
      />
    </div>
  </div>
  <div class="row secondRow">
    <div class="col-6">
      <label for="editWikiURL">Wiki URL:</label>
    </div>
    <div class="col-6 text-right">
      <a [href]="wikiLink()" target="_new">{{
        personLocal.wikiURL === "" ? "Search" : "Link"
      }}</a>
    </div>
  </div>
  <div class="row">
    <div class="col-12 edit">
      <input
        type="text"
        id="editWikiURL"
        [(ngModel)]="personLocal.wikiURL"
        class="w-100"
      />
    </div>
  </div>
  <div class="row secondRow">
    <div class="col-12">
      <label for="editStatus">Status:</label>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div ngbDropdown class="d-inline-block w-100">
        <button
          class="btn btn-outline-primary w-100 ddStatus"
          id="dropdownBasic1"
          ngbDropdownToggle
        >
          {{ personLocal.status }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button
            ngbDropdownItem
            class="ddStatus"
            (click)="personLocal.status = ePeopleStatus.ALIVE"
          >
            {{ ePeopleStatus.ALIVE }}
          </button>
          <button
            ngbDropdownItem
            class="ddStatus"
            (click)="personLocal.status = ePeopleStatus.DEAD"
          >
            {{ ePeopleStatus.DEAD }}
          </button>
          <button
            ngbDropdownItem
            class="ddStatus"
            (click)="personLocal.status = ePeopleStatus.PENDING"
          >
            {{ ePeopleStatus.PENDING }}
          </button>
          <button
            ngbDropdownItem
            class="ddStatus"
            (click)="personLocal.status = ePeopleStatus.PENDING_DEAD"
          >
            {{ ePeopleStatus.PENDING_DEAD }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-6">
      {{ personLocal.statusChangeRequestedBy }}
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default cancel" (click)="cancelDialog()">
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-success submit"
    [disabled]="!changesMade()"
    (click)="updatePerson()"
  >
    <i *ngIf="spinning" class="fas fa-spinner fa-spin"></i>&nbsp;Submit
  </button>
</div>
