<div class="row personContainer">
  <div class="col-4 text-right">Make your entry:</div>
  <div class="col-4 text-left personEntry">
    <input
      id="person"
      type="text"
      class="person"
      [class.is-invalid]="searchFailed"
      [(ngModel)]="personSelection"
      [ngbTypeahead]="search"
      placeholder="People search"
      [resultFormatter]="displayPeopleSearchResultValue"
      [inputFormatter]="inputPeopleSearchResultValue"
    />
  </div>
  <div class="col-4 text-left">
    <button
      (click)="addPerson()"
      [disabled]="personSelection === ''"
      class="btn btn-success personBtn"
    >
      Add Entry
    </button>
  </div>
</div>
<ng-template #newPersonModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add new person</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('closed')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        To add a new person not already in our database the person must exist
        within Wikipedia. Below is a list of people what match your entry.
      </div>
    </div>
    <div class="row">
      <div class="col-8 text-center">Name</div>
      <div class="col-2 text-center">Wiki</div>
      <div class="col-2">&nbsp;</div>
    </div>
    <div *ngFor="let name of wikiSearch">
      <div class="row">
        <div class="col-8 text-center">
          {{ name }}
        </div>
        <div class="col-2 text-center">
          <a [href]="getWikiURL(name)" target="_new">Open</a>
        </div>
        <div class="col-2 text-center">
          <button
            type="button"
            class="btn btn-default select"
            (click)="wikiSelected(name)"
          >
            Select
          </button>
        </div>
      </div>
    </div>
    <div class="row text-center mt-10">
      <div class="col-12 text-center">
        <button
          type="button"
          class="btn btn-default cancel"
          (click)="modal.close('cancel')"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #personModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm Manual Entry</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('closed')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        The entry you made is a match for one or more people in our database.
        Please select from the list below, or select the "Add New" button make a
        new entry.
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-4 text-center underlineRow">Name</div>
        <div class="col-3 text-center underlineRow">Age</div>
        <div class="col-3 text-center underlineRow">Date of Death</div>
        <div class="col=2">&nbsp;</div>
      </div>
    </div>
    <div *ngFor="let person of people">
      <div class="row">
        <div class="col-4 text-center">
          {{ person.name }}
        </div>
        <div class="col-3 text-center">
          {{ calculateAge(person).toString() }}
        </div>
        <div class="col-3 text-center">
          {{ person.dateOfDeath | date : "dd/MM/yyyy" }}
        </div>
        <div class="col-2 text-center">
          <button
            *ngIf="!person.dateOfDeath"
            type="button"
            class="btn btn-default select"
            (click)="personSelected(person)"
          >
            Select
          </button>
        </div>
      </div>
    </div>
    <div class="row text-center mt-10">
      <div class="col-6 text-right">
        <button
          type="button"
          class="btn btn-default cancel"
          (click)="modal.close('cancel')"
        >
          Cancel
        </button>
      </div>
      <div class="col-6 text-left">
        <button
          type="button"
          (click)="addNewPerson()"
          class="btn btn-success submit"
        >
          Add New
        </button>
      </div>
    </div>
  </div>
</ng-template>
