<div class="container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <p>Located at the corner of 3rd Street and Main at</p>
      <p class="address">228 West Main Street, St Charles, IL 60174</p>
      <p>
        Free parking is available at several lots within a block radius of the
        studio:
      </p>
      <img class="image-width" src="images/gm_map.jpg" alt="" />
      <p>
        To get directions to the store click
        <a href="https://goo.gl/maps/zNzybbu67Yz" target="_new">here</a>
        (creates new window in Google Maps).
      </p>
    </div>
    <div class="col-md-1"></div>
  </div>
  <!-- <div class="row">
    <div class="col-md-12 text-center hours-title">WINTER HOURS</div>
  </div>
  <div class="row">
    <div class="col-lg-3 text-center days">
      <span class="days">MON</span><br />
      <span class="hours">CLOSED</span><br />
    </div>
    <div class="col-lg-3 text-center days">
      <span class="days">TUE - THU</span><br />
      <span class="hours">12PM - 8PM</span><br />
    </div>
    <div class="col-lg-3 text-center days">
      <span class="days">FRI - SAT</span><br />
      <span class="hours">12PM - 10PM</span><br />
    </div>
    <div class="col-lg-3 text-center days">
      <span class="days">SUN</span><br />
      <span class="hours">12PM - 5PM</span><br />
    </div>
  </div> -->
  <!-- <div class="row">
    <div class="col-md-12 text-center hours-title">SUMMER HOURS</div>
  </div> -->
  <div class="row">
    <div class="col-lg-6 text-center days">
      <span class="days">SUN - THU</span><br />
      <span class="hours">12PM - 9PM</span><br />
    </div>
    <div class="col-lg-6 text-center days">
      <span class="days">FRI - SAT</span><br />
      <span class="hours">12PM - 10PM</span><br />
    </div>
  </div>
  <div class="spacer"></div>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <h1 class="contact">Contact Information</h1>
      <p class="phone nomobile">
        Store Phone Number: <a href="tel:6304412920">630-441-2920</a>
      </p>
      <p class="phone mobile">
        Store Phone Number:<br /><a href="tel:6304412920">630-441-2920</a>
      </p>
      <p>
        Email:
        <a href="mailto:info@ghoulishmortals.com"
          >info&#64;ghoulishmortals.com</a
        >
      </p>
      <p>
        <a href="https://www.facebook.com/ghoulishmortals/" target="_new"
          >Facebook</a
        >
        |
        <a
          href="https://www.instagram.com/ghoulishmortalschicago/"
          target="_new"
          >Instagram</a
        >
        |
        <a href="https://twitter.com/GhoulishMortals" target="_new">Twitter</a>
      </p>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
