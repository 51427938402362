<div class="container gm-border rounded">
  <div class="row">
    <div class="col-12 text-center">
      <h1>2021 Ghoul Pool (Ended)</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <h1>Winners</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      Congratulations to Britt Johnson-Dunlop for being the winner of the 2021
      Ghoul Pool! Britt wins a $100 Ghoulish Mortals Gift Card! Here are how
      everyone that earnt points ranks against each other.
    </div>
  </div>
  <div class="row" *ngFor="let item of winners">
    <div class="col-3" [ngClass]="{ header: item.header }">
      {{ item.name }}
    </div>
    <div class="col-3" [ngClass]="{ header: item.header }">
      {{ item.deaths }}
    </div>
    <div class="col-2" [ngClass]="{ header: item.header }">
      {{ item.points }}
    </div>
    <div class="col-1" [ngClass]="{ header: item.header }">
      {{ item.pointTotal }}
    </div>
    <div class="col-3" [ngClass]="{ header: item.header }">
      {{ item.bonuses }}
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p class="text-center">
        Happy 2021 Creeples... the 3rd Annual Ghoul Pool shall soon commence! We
        know that there are a ton of you out there as morbid as we are, so here
        is your chance to win some Ghoulish Mortals gift cards during 2021 for
        simply having a sick sense of humor.
      </p>
      <p class="text-center">
        So most of you know what a Ghoul Pool is... for those that don't, its
        simply a Celebrity Death prediction game. Our version is FREE and allows
        for you to email or direct message us the names of 13 well known
        celebrities (and 1 alternate) who are famous enough to have their demise
        reported on multiple news/social media outlets. As the year goes by,
        your choices, whether on your list by luck, strategy or just wishful
        thinking will win you prizes. The general point calcuation formula and
        the special circumstances points will be explained below. There are also
        several special morbid circumstances prizes/drawings throughout the year
        that you can win as well.
      </p>
      <p class="text-center">
        All lists must be in by January 14th at midnight, CST. Game play runs
        from Jan 15th, 2021-12:00am thru Dec. 31, 2021-11:59pm. If you submit
        your list prior to the deadline and one of your celebrities dies before
        the official 2021 gameplay starts, that celebrity is dropped from your
        list and then your alternate drops into your list of 13. If your
        alternate dies, then damn, thats creepy and one of us needs to contact
        the other to replace your alternate. (The alternate is used only during
        this pre game start period and never used under any other circumstances.
        So this time next year you can't argue points because your alternate
        died after the game started)
      </p>
      <p class="text-center">
        Just email
        <a href="mailto:ghoulpool@ghoulishmortals.com"
          >ghoulpool&#64;ghoulishmortals.com</a
        >
        or direct message us on social media with your simple list of 13
        celebrity names, plus one extra name for an alternate. No paragraphs,
        diatribes or poems. Just the list, numbered from 1-13 with that extra
        celebrity marked as the alternate. So that we can easily add your entry
        to our database, please list your name, cell number and email address
        above your list, so we are not having to go back and sleuth you out if
        you win something. We aren't going to use any of it to spam you or
        anything, we just need a way of contacting you.
      </p>
      <p class="text-center">
        If we need clarification of someone you have placed on the list, we will
        contact you. Go with what they go by or their stage name, don't try to
        be witty and use their birth name. For example, don't say Stefani
        Germanotta, just go with Lady Gaga. Otherwise it just wastes time with
        our taking a moment to roll our eyes and dramatically sigh as we
        begrudgingly enter the odd name into a browser to figure out who you are
        referring to.
      </p>
      <p class="text-center">
        Please do not publish your list on social media for other players or the
        public to see til AFTER the game starts. Why you ask? It's because some
        people are just assholes, think they are being funny, are totally not up
        on their pop culture or don't have a lot of time and will straight-out
        copy your choices, messing with your chances of getting at the prizes.
        Please also check your list for dead people. If you submit someone that
        is already dead, like someone did last year despite the warning, we
        aren't going to contact you... you just lowered your chances of making
        your total all it could be. Same with submitting lists with less than 13
        names/an alternate... you are welcome to do so and still could
        technically win something, but you might as well do it the right away
        and increase your chances.
      </p>
      <p>
        One entry per person with matching ID. So don't create a zillion gmail
        accounts thinking you are working the system. We are a little mom and
        pop store just trying to throw a little mischievous humor your way to
        make 2021 fun... don't be the rotten apple that spoils the barrel. Gift
        card prizes must be picked up in person with matching ID, and can only
        be redeemed for store merchandise, never any cash.
      </p>
      <h2>Kick the Bucket General Point Formula:</h2>
      <p class="text-center">
        Points will be awarded by the persons age subtracted from 105. So if you
        pick Prince Philip, thinking it's a sure bet, then you get 6 points
        because 99 from 105... so don't pick super old people. If you pick a
        Kardashian (insert your own snide remark here) then you would get
        serious points due to their youth.
      </p>
      <p class="text-center">
        Essentially the magic equation is 105 minus the age of each dead
        celebrity on your list + all applicable bonus points = total score.
        Winner of the most points will be deemed the 2021 Grim Reaper and win a
        $100 gift card for the store! In the event of a tie for the 2021 Grim
        Reaper Award, the gift card will be divided accordingly amongst those
        that tied.
      </p>
      <h2>Special circumstances bonus points:</h2>
    </div>
    <div class="col-2 nowrap text-right">10 pts</div>
    <div class="col-10">
      The <u>“Be Still My Heart”</u> if your celebrity dies of a heart attack.
    </div>
    <div class="col-2 nowrap text-right">10 pts</div>
    <div class="col-10">
      The <u>“Dead of Night”</u> if your celebrity dies peacefully in their
      sleep of natural causes.
    </div>
    <div class="col-2 nowrap text-right">15 pts</div>
    <div class="col-10">
      The <u>“New Blood”</u> if your celebrity dies in January after game play
      official starts on January 15th.
    </div>
    <div class="col-2 nowrap text-right">15 pts</div>
    <div class="col-10">
      The <u>“Random Pick”</u> for each of your celebrity deaths are not picked
      by anyone else in the game.
    </div>
    <div class="col-2 nowrap text-right">20 pts</div>
    <div class="col-10">
      The <u>“Long Nap”</u> If your celebrity dies from an accidental drug or
      alcohol overdose (confirmed by toxicology report before the end of
      gameplay)
    </div>
    <div class="col-2 nowrap text-right">20 pts</div>
    <div class="col-10">
      The <u>“Sink or Swim”</u> if your celebrity drowns (extra 10 points if
      they are found in a bathtub)
    </div>
    <div class="col-2 nowrap text-right">25 pts</div>
    <div class="col-10">
      The <u>“Dead Last”</u> if your celebrity is a sports figure that dies
      competing or playing his/her sport.
    </div>
    <div class="col-2 nowrap text-right">25 pts</div>
    <div class="col-10">
      The <u>“Wishful Thinking”</u> if your celebrity is a political figure and
      is assassinated.
    </div>
    <div class="col-2 nowrap text-right">25 pts</div>
    <div class="col-10">
      The <u>“As The Crow Flies”</u> if your celebrity is a musician or actor
      and dies while performing or working on set.
    </div>
    <div class="col-2 nowrap text-right">25 pts</div>
    <div class="col-10">
      The <u>“Too Fast, Too Furious”</u> if your celebrity dies during the time
      period he/she is filming a movie and his/her contribution to the movie was
      not finished.
    </div>
    <div class="col-2 nowrap text-right">30 pts</div>
    <div class="col-10">
      The <u>“Total Blast”</u> If your celebrity dies from suicide by gun.
    </div>
    <div class="col-2 nowrap text-right">30 pts</div>
    <div class="col-10">
      <u>“Just Hanging Out”</u> if you celebrity commits suicide by hanging.
    </div>
    <div class="col-2 nowrap text-right">30 pts</div>
    <div class="col-10">
      The <u>“Crash Cart”</u> if your celebrity dies in an auto accident. (Extra
      10 pts. if the celebrity is driving).
    </div>
    <div class="col-2 nowrap text-right">30 pts</div>
    <div class="col-10">
      The <u>“Gravity!!!”</u> if your celebrity dies in plane or helicopter
      crash. (Extra 10 pts. if the celebrity is piloting).
    </div>
    <div class="col-2 nowrap text-right">35 pts</div>
    <div class="col-10">
      The <u>“You Better Watch Out”</u> if your celebrity dies on Christmas.
    </div>
    <div class="col-2 nowrap text-right">50 pts</div>
    <div class="col-10">
      The <u>“Awkward!!!”</u> if your celebrity dies in an indecent manner.
      (Extra 25 points if they David Carradine themselves)
    </div>
    <div class="col-2 nowrap text-right">50 pts</div>
    <div class="col-10">
      The <u>“Unhappy Birthday”</u> if your celebrity dies on his/her birthday.
    </div>
    <div class="col-2 nowrap text-right">50 pts</div>
    <div class="col-10">
      The <u>“Darwin Award”</u> if your celebrity dies from his/her own
      accidental stupidity like slipping off a cliff while taking a selfie
      (overdoses do not count).
    </div>
    <div class="col-2 nowrap text-right">50 pts</div>
    <div class="col-10">
      The <u>“Murder Suicide”</u> if your celebrity murders someone and then
      themselves (Extra 10 pts. if the celebrity is murdered by their spouse or
      partner)
    </div>
    <div class="col-2 nowrap text-right">50 pts</div>
    <div class="col-10">
      The <u>“Double Feature”</u> if 2 of your celebrities die on the same day
      like Michael Jackson and Farrah Fawcett. (Extra 50 points if those
      celebrities die in the same accident/incident)
    </div>
    <div class="col-2 nowrap text-right">100 pts</div>
    <div class="col-10">
      The <u>“Dropping Like Flies”</u> if 3 of your predictions die in a row
      with nobody else's predictions in between.
    </div>
    <div class="col-2 nowrap text-right">100 pts</div>
    <div class="col-10">
      The <u>“Last Gasp”</u> for the last celebrity to die by 11:59 p.m. CST on
      December 31, 2021.
    </div>
    <div class="col-2 nowrap text-right">150 pts</div>
    <div class="col-10">
      The <u>“Just Trying to Get Ahead”</u> if your celebrity is decapitated...
      plus you get a free Little Jar of Horror of your choice!
    </div>
    <div class="col-12 margin-top-10">
      <p class="text-center">
        You are welcome to suggest another snarky bonus point category before
        the game starts. Just let us know if you can think of one and we likely
        will add it!
      </p>
      <h2>Throughout the Year Prizes/Drawings:</h2>
      <p class="text-center">
        These extra bonus prizes are awarded throughout gameplay of Jan 15th,
        2021-12:00am thru Dec. 31, 2021-11:59pm. You might win outright if you
        are the only person on the list that picked that celebrity, but in case
        multiple people picked that person, we go into random drawing mode for
        that prize.
      </p>
      <ul>
        <li>
          <u>“First to Die”</u> award - if one of your celebrities is the first
          to die - $25 gift card
        </li>
        <li>
          <u>“Friday the 13th”</u> award - if one of your celebrities dies on a
          Friday the 13th - $50 gift card
        </li>
        <li>
          <u>“Final Destination”</u> award - if one of your celebrities dies in
          a particularly fucked up, random manner (think Anton Yelchin) - $50
          gift card
        </li>
        <li>
          <u>“Predator”</u> award - if one of your celebrities is stalked and
          murdered - $50 gift card
        </li>
        <li>
          <u>“Halloween”</u> award - if one of your celebrities dies on
          Halloween - $50 gift card
        </li>
        <li>
          <u>“Reap Baby Reap”</u> award - if more celebrities on your list die
          than on anyone elses list (must be more than 1) - $50 gift card
        </li>
      </ul>
      <p class="text-center">
        You are welcome to suggest another special prize category before the
        game starts. Just let us know if you can think of one and we likely will
        add it!
      </p>
      <h2>Extra Rules and Reminders:</h2>
      <p class="text-center">
        Remember... the alternate pick is used only in the very unlikely
        situation that any of your 13 celebs die between when you submit your
        list and when the game starts. The alternate is NEVER used under any
        other circumstances.
      </p>
      <p class="text-center">
        Death row inmates don't count unless another inmate kills them or they
        die of a disease. You can put them on the list, but their scheduled
        execution will not count.
      </p>
      <p class="text-center">
        Sick celebrities can be placed on the list. This is where research comes
        in handy.
      </p>
      <p class="text-center">
        One entry per person with matching id. Prizes must be picked up in
        person.
      </p>
      <p class="text-center">
        Gift cards have no cash value. You have to use them for purchasing
        products at Ghoulish Mortals, our brick and mortar store in Chicago, IL.
        Gift cards can be used in multiple visits, but there is no cash back.
      </p>
    </div>
  </div>
</div>
