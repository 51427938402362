import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbDatepickerModule,
  NgbDateStruct,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  People,
  PeopleStatus,
  PeopleUpdateDTO,
} from '../../../../models/people';
import { PeopleService } from '../../../../services/people.service';
import { EmailService } from '../../../../services/email.service';

@Component({
  selector: 'app-people-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    RouterModule,
  ],
  templateUrl: './people-edit.component.html',
  styleUrl: './people-edit.component.scss',
})
export class PeopleEditComponent implements OnInit {
  @Input()
  person: People;

  @Input()
  type: string;

  @Output()
  returnOption: EventEmitter<string> = new EventEmitter();

  @Output()
  returnPerson: EventEmitter<People> = new EventEmitter();

  private personSave: People;
  public personLocal: People;
  public dateOfBirth: NgbDateStruct | null;
  public dateOfDeath: NgbDateStruct | null;
  ePeopleStatus = PeopleStatus;

  public spinning = false;

  constructor(
    private peopleService: PeopleService,
    private emailService: EmailService
  ) {}

  ngOnInit(): void {
    this.personSave = new People(
      this.person.id,
      this.person.name,
      this.person.knownAs,
      this.person.dateOfBirth,
      this.person.dateOfDeath,
      this.person.causeOfDeath,
      this.person.wikiURL,
      this.person.search,
      this.person.status,
      this.person.statusChangeRequestedBy
    );
    this.personLocal = new People(
      this.person.id,
      this.person.name,
      this.person.knownAs,
      this.person.dateOfBirth,
      this.person.dateOfDeath,
      this.person.causeOfDeath,
      this.person.wikiURL,
      this.person.search,
      this.person.status,
      this.person.statusChangeRequestedBy
    );
    this.dateOfBirth = this.extractDate(this.person.dateOfBirth);
    this.dateOfDeath = this.extractDate(this.person.dateOfDeath);
  }

  extractDate(incomingDate: Date | null) {
    if (incomingDate) {
      const d = new Date(incomingDate);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();
      return { year, month, day };
    } else {
      return null;
    }
  }

  cancelDialog() {
    this.returnOption.emit('cancel');
  }

  updatePerson() {
    if (this.changesMade()) {
      const personUpdate = new PeopleUpdateDTO(
        this.personLocal.name,
        this.personLocal.knownAs,
        this.personLocal.dateOfBirth,
        this.personLocal.dateOfDeath,
        this.personLocal.causeOfDeath,
        this.personLocal.wikiURL,
        this.personLocal.search,
        this.personLocal.status,
        this.personLocal.statusChangeRequestedBy
      );

      // if the change was marking someone dead based on a user request, then send the user an email
      // about the update.
      if (
        this.personLocal.status === PeopleStatus.PENDING_DEAD &&
        this.personSave.statusChangeRequestedBy !== ''
      ) {
        let body = '';
        body += '<p>';
        body +=
          'You recently marked one of the celebrities from you Ghoul Pool entry as dead. ';
        body +=
          'After performing some checks we can confirm your request and show that ';
        body +=
          this.personLocal.name +
          ' dead on ' +
          new Date(
            this.personLocal.dateOfDeath
              ? this.personLocal.dateOfDeath
              : '1970-01-01'
          ).toLocaleDateString();
        body +=
          ' from ' + this.personLocal.causeOfDeath !== ''
            ? ' ' + this.personLocal.causeOfDeath
            : ' unknown causes.';
        body += '</p>';
        body += '<p>';
        body +=
          'We will make changes to the points shortly. Thanks for your efforts in keeping our system accurate!';
        body += '</p>';

        this.emailService.send(
          this.personSave.statusChangeRequestedBy,
          'Ghoul Pool update - Notice of celebrity death update',
          body,
          true
        );
        personUpdate.status = PeopleStatus.DEAD;
        personUpdate.statusChangeRequestedBy = '';
      }

      // if we don't have a record id then this is a create
      if (this.personLocal.id === 0) {
        this.peopleService.create(personUpdate).subscribe((result) => {
          const person: People = result;
          this.returnPerson.emit(person);
        });
      } else {
        this.peopleService
          .update(this.personLocal.id, personUpdate)
          .subscribe((result) => {
            const person: People = result;
            this.returnPerson.emit(person);
          });
      }
    }
  }

  dodChange() {
    if (this.personLocal.dateOfDeath) {
      if (this.personSave.status === PeopleStatus.PENDING) {
        this.personLocal.status = PeopleStatus.PENDING_DEAD;
      } else {
        this.personLocal.status = PeopleStatus.DEAD;
      }
    } else {
      if (this.personSave.status === PeopleStatus.PENDING) {
        this.personLocal.status = PeopleStatus.PENDING;
      } else {
        this.personLocal.status = PeopleStatus.ALIVE;
      }
    }
  }

  changesMade(): boolean {
    this.personLocal.dateOfBirth = this.dateOfBirth
      ? new Date(
          this.dateOfBirth.year,
          this.dateOfBirth.month - 1,
          this.dateOfBirth.day
        )
      : null;
    this.personLocal.dateOfDeath = this.dateOfDeath
      ? new Date(
          this.dateOfDeath.year,
          this.dateOfDeath.month - 1,
          this.dateOfDeath.day
        )
      : null;

    if (
      this.compareDates(
        this.personLocal.dateOfDeath,
        this.personSave.dateOfDeath
      )
    ) {
      setTimeout(() => {
        this.dodChange();
      }, 100);
    }

    let changes = false;
    changes =
      this.personLocal.causeOfDeath !== this.personSave.causeOfDeath
        ? true
        : changes;
    changes = this.compareDates(
      this.personLocal.dateOfBirth,
      this.personSave.dateOfBirth
    )
      ? true
      : changes;
    changes = this.compareDates(
      this.personLocal.dateOfDeath,
      this.personSave.dateOfDeath
    )
      ? true
      : changes;
    changes =
      this.personLocal.knownAs !== this.personSave.knownAs ? true : changes;
    changes = this.personLocal.name !== this.personSave.name ? true : changes;
    changes =
      this.personLocal.search !== this.personSave.search ? true : changes;
    changes =
      this.personLocal.wikiURL !== this.personSave.wikiURL ? true : changes;
    changes =
      this.personLocal.status !== this.personSave.status ? true : changes;

    return changes;
  }

  compareDates(date1: Date | null, date2: Date | null): boolean {
    date1 = date1 ? new Date(date1) : new Date('1970-01-01');
    date2 = date2 ? new Date(date2) : new Date('1970-01-01');
    return date1.toLocaleDateString() !== date2.toLocaleDateString();
  }

  wikiLink() {
    let url = this.personLocal.wikiURL;
    if (url === '') {
      url =
        'https://en.wikipedia.org/wiki/' +
        this.personLocal.name.replace(' ', '_');
    }
    return url;
  }
}
