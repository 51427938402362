import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GlobalService } from '../../../../services/global.service';
import { Contestant, ContestantUpdateDTO } from '../../../../models/contestant';
import { ContestantService } from '../../../../services/contestant.service';

@Component({
  selector: 'app-view-competition-contestants-edit',
  standalone: true,
  imports: [CommonModule, NgbDatepickerModule, FormsModule, RouterModule],
  templateUrl: './view-competition-contestants-edit.component.html',
  styleUrl: './view-competition-contestants-edit.component.scss',
})
export class ViewCompetitionContestantsEditComponent implements OnInit {
  @Input()
  contestant: Contestant;

  @Input()
  type: string;

  @Output()
  returnOption: EventEmitter<string> = new EventEmitter();

  @Output()
  returnContestant: EventEmitter<Contestant> = new EventEmitter();

  private contestantSave: Contestant;
  public contestantLocal: Contestant;

  public spinning = false;
  public deleting = false;

  constructor(
    private globalService: GlobalService,
    private contestantService: ContestantService
  ) {}

  ngOnInit(): void {
    this.contestantSave = new Contestant(
      this.contestant.id,
      this.contestant.name,
      this.contestant.email,
      this.contestant.telephone
    );
    this.contestantLocal = new Contestant(
      this.contestant.id,
      this.contestant.name,
      this.contestant.email,
      this.contestant.telephone
    );
  }

  cancelDialog() {
    this.returnOption.emit('cancel');
  }

  updateContestant() {
    if (this.changesMade()) {
      const contestantUpdate = new ContestantUpdateDTO(
        this.contestantLocal.name,
        this.contestantLocal.email,
        this.contestantLocal.telephone
      );

      // if we don't have a record id then this is a create
      if (this.contestantLocal.id === 0) {
        this.contestantService
          .create(this.globalService.adminSelectionId, contestantUpdate)
          .subscribe((result) => {
            const contestant: Contestant = result;
            this.returnContestant.emit(contestant);
          });
      } else {
        this.contestantService
          .update(this.contestantLocal.id, contestantUpdate)
          .subscribe((result) => {
            const contestant: Contestant = result;
            this.returnContestant.emit(contestant);
          });
      }
    }
  }

  changesMade(): boolean {
    let changes = false;

    changes =
      this.contestantLocal.name !== this.contestantSave.name ? true : changes;
    changes =
      this.contestantLocal.email !== this.contestantSave.email ? true : changes;
    changes =
      this.contestantLocal.telephone !== this.contestantSave.telephone
        ? true
        : changes;

    return changes;
  }

  deleteContestant(id: number) {
    this.contestantService.delete(id).subscribe((result) => {
      this.returnOption.emit('delete/' + id.toString());
    });
  }
}
