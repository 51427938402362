<div class="row emailRow">
  <div class="col-4 text-right label">Email Address:</div>
  <div class="col-4 emailEntry">
    <input
      type="email"
      id="email"
      name="email"
      required
      [(ngModel)]="localContestant.email"
      (keyup)="onEmailChange($event)"
      class="email"
    />
  </div>
  <div class="col-4 text-left emailButton">
    <button
      (click)="clickEmail()"
      [disabled]="!isEmailEntered || lookupSearching"
      class="btn btn-success emailBtn"
    >
      <i *ngIf="lookupSearching" class="fas fa-spinner fa-spin"></i>Lookup
    </button>
  </div>
</div>
<ng-template #newContestant let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Register new email</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('closed')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        The email you entered was not found, please enter all the details below
        and click submit.
      </div>
    </div>
    <div class="row contestantEmail">
      <div class="col-4 text-right label">Email:</div>
      <div class="col-8 text-left emailEntry">
        {{ localContestant.email }}
      </div>
    </div>
    <div class="row contestantName">
      <div class="col-4 text-right label">Name:</div>
      <div class="col-8 text-left nameEntry">
        <input
          type="text"
          id="contestantName"
          name="contestantName"
          required
          [(ngModel)]="localContestant.name"
          class="name"
        />
      </div>
    </div>
    <div class="row contestantPhone">
      <div class="col-4 text-right label">Phone:</div>
      <div class="col-8 text-left phoneEntry">
        <input
          type="text"
          id="contestantPhone"
          name="contestantPhone"
          required
          [(ngModel)]="localContestant.telephone"
          class="phone"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-default cancel"
      (click)="modal.close('cancel')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success submit"
      [disabled]="disableNewContestantSubmit()"
      (click)="newContestantSubmit()"
    >
      <i *ngIf="contestantSave" class="fas fa-spinner fa-spin"></i>&nbsp;Submit
    </button>
  </div>
</ng-template>
