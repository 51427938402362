import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../../services/global.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbNav, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CompetitionViewComponent } from '../../components/competition-view/competition-view.component';
import { PeopleViewComponent } from '../../components/people-view/people-view.component';

@Component({
  selector: 'app-ghoulpool-admin',
  standalone: true,
  imports: [
    CompetitionViewComponent,
    PeopleViewComponent,
    RouterModule,
    NgbNavModule,
    PeopleViewComponent,
  ],
  templateUrl: './ghoulpool-admin.component.html',
  styleUrl: './ghoulpool-admin.component.scss',
})
export class GhoulpoolAdminComponent implements OnInit {
  constructor(
    public globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.globalService.isAdmin = true;
    }, 100);

    this.globalService.adminTab = 1;
    this.globalService.adminSelectionId = 0;
    this.globalService.adminSubTab = 1;

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.globalService.adminTab = Number(params.tab);
      }
      if (params.type) {
        this.globalService.adminType = String(params.type);
      }
      if (params.selectionId) {
        this.globalService.adminSelectionId = Number(params.selectionId);
      }
      if (params['subtab']) {
        this.globalService.adminSubTab = Number(params['subtab']);
      }
      this.setQueryParams();
      console.log(this.globalService);
    });
  }

  setQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.globalService.adminQueryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}
