import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GhoulPoolDeaths, GhoulPoolWinners } from '../../../models/ghoulpool';

@Component({
  selector: 'app-ghoulpool2021',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './ghoulpool2021.component.html',
  styleUrl: './ghoulpool2021.component.scss',
})
export class Ghoulpool2021Component implements OnInit {
  public winners: Array<GhoulPoolWinners> = [];
  public deaths: Array<GhoulPoolDeaths> = [];

  constructor() {}

  ngOnInit() {
    this.buildGhoulPoolInfo();
  }

  buildGhoulPoolInfo() {
    this.winners = [
      {
        name: 'Name',
        deaths: 'Deaths',
        points: 'Points',
        pointTotal: 'Total',
        bonuses: 'Bonues',
        header: true,
      },
      {
        name: 'Britt Johnson-Dunlop',
        deaths:
          'Dustin Diamond - Age (61 pts), ' +
          'Larry King - Age (18 pts), New Blood (15 pts), First to Die ($25 Shared), ' +
          'Cloris Leachman - Age (11 pts), New Blood (15 pts), Random Pick (15 pts), ' +
          'Betty White - Age (6pts), Dead of Night (10 pts), Last Gasp (100 pts)',
        points: '61 + 18 + 15 + 11 + 15 + 15 + 6 + 10 + 100',
        pointTotal: '251',
        bonuses:
          'First to Die ($25 Shared), Reap Baby Reap ($50 Split), Grim Reaper ($100)',
        header: false,
      },
      {
        name: 'Joanna Besser',
        deaths:
          'Dustin Diamond - Age (61 pts), ' +
          'Betty White - Age (6pts), Dead of Night (10 pts), Last Gasp (100 pts)',
        points: '61 + 6 + 10 + 100',
        pointTotal: '177',
        bonuses: '',
        header: false,
      },
      {
        name: 'Kelly McKee',
        deaths:
          'Larry King - Age (18 pts), New Blood (15 pts), First to Die ($25 Shared), ' +
          'Rush Limbaugh - Age (35 pts), ' +
          'Bernie Madoff - Age (23 pts), Dead of Night (10 pts), Random Pick (15 pts), ' +
          'Leon Spinks - Age (38 pts)',
        points: '23 + 10 + 15 + 18 + 15 + 35 + 38',
        pointTotal: '154',
        bonuses: 'First to Die ($25 Shared), Reap Baby Reap ($50 Split)',
        header: false,
      },
      {
        name: 'Christina Dover',
        deaths:
          'Rush Limbaugh - Age (35 pts), ' +
          'Betty White - Age (6pts), Dead of Night (10 pts), Last Gasp (100 pts)',
        points: '35 + 6 + 10 + 100',
        pointTotal: '151',
        bonuses: '',
        header: false,
      },
      {
        name: 'Stella Jeser',
        deaths:
          'Larry King - Age (18 pts), New Blood (15 pts), First to Die ($25 Shared), ' +
          'Betty White - Age (6pts), Dead of Night (10 pts), Last Gasp (100 pts)',
        points: '18 + 15 + 6 + 10 + 100',
        pointTotal: '149',
        bonuses: 'First to Die ($25 Shared)',
        header: false,
      },
      {
        name: 'Amber Jasmine Nugent',
        deaths:
          'Larry King - Age (18 pts), New Blood (15), First to Die ($25 Shared), ' +
          'Betty White - Age (6pts), Dead of Night (10 pts), Last Gasp (100 pts)',
        points: '18 + 15 + 6 + 10 + 100',
        pointTotal: '149',
        bonuses: 'First to Die ($25 Shared)',
        header: false,
      },
      {
        name: 'Erica Orlowski-Liedberg',
        deaths:
          'Betty White - Age (6pts), Dead of Night (10 pts), Last Gasp (100 pts)',
        points: '116',
        pointTotal: '116',
        bonuses: '',
        header: false,
      },
      {
        name: 'Katrina Handrock',
        deaths:
          'Betty White - Age (6pts), Dead of Night (10 pts), Last Gasp (100 pts)',
        points: '116',
        pointTotal: '116',
        bonuses: '',
        header: false,
      },
      {
        name: 'Heather Semmler',
        deaths:
          'Betty White - Age (6pts), Dead of Night (10 pts), Last Gasp (100 pts)',
        points: '116',
        pointTotal: '116',
        bonuses: '',
        header: false,
      },
      {
        name: 'Mark Gordon',
        deaths:
          'Bob Dole - Age (7 pts), ' +
          'Rush Limbaugh - Age (35 pts), ' +
          'Prince Philip - Age (6 pts), Dead of Night (10 pts), Random Pick (15 pts)',
        points: '7 + 35 + 6 + 10 + 15',
        pointTotal: '73',
        bonuses: '',
        header: false,
      },
      {
        name: 'Wes Kadas',
        deaths: 'Rush Limbaugh - Age (35 pts), ' + 'Leon Spinks - Age (38 pts)',
        points: '35 + 38',
        pointTotal: '73',
        bonuses: '',
        header: false,
      },
      {
        name: 'Mike Trejo',
        deaths:
          'Larry King - Age (18 pts), New Blood (15), First to Die ($25 Shared), ' +
          'Rush Limbaugh - Age (35 pts)',
        points: '18 + 15 + 35',
        pointTotal: '68',
        bonuses: 'First to Die ($25 Shared)',
        header: false,
      },
      {
        name: 'Lou Gabriel',
        deaths:
          'Ed Asner - Age (14 pts), Dead of Night (10 pts), ' +
          'Rush Limbaugh - Age (35 pts)',
        points: '14 + 10 + 35',
        pointTotal: '59',
        bonuses: '',
        header: false,
      },
      {
        name: 'Dante Malkavia',
        deaths: 'Bob Dole - Age (7 pts), ' + 'Rush Limbaugh - Age (35 pts)',
        points: '7 + 35',
        pointTotal: '42',
        bonuses: '',
        header: false,
      },
      {
        name: 'Dan Liedberg',
        deaths: 'Leon Spinks - Age (38 pts)',
        points: '38',
        pointTotal: '38',
        bonuses: '',
        header: false,
      },
      {
        name: 'John Arndt',
        deaths: 'Rush Limbaugh - Age (35 pts)',
        points: '35',
        pointTotal: '35',
        bonuses: '',
        header: false,
      },
      {
        name: 'Dirk Stewart',
        deaths: 'Rush Limbaugh - Age (35 pts)',
        points: '35',
        pointTotal: '35',
        bonuses: '',
        header: false,
      },
      {
        name: 'Luke Levi',
        deaths: 'Bobby Bowden - Age (14 pts), Random Pick (15 pts)',
        points: '14 + 15',
        pointTotal: '29',
        bonuses: '',
        header: false,
      },
      {
        name: 'Steve Clevenger',
        deaths: 'Ed Asner - Age (14 pts), Dead of Night (10 pts)',
        points: '14 + 10',
        pointTotal: '24',
        bonuses: '',
        header: false,
      },
      {
        name: 'Scott McGregor',
        deaths: 'Bob Dole - Age (7 pts)',
        points: '7',
        pointTotal: '7',
        bonuses: '',
        header: false,
      },
    ];
  }
}
