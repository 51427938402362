<div class="container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <p>
        Would you like to be a Ghoulish Mortals artist? If you believe some of
        your work would fit well into one or more of our themed sections, please
        message us with examples of your work, and your ideal price point, and
        we will check it out! If we would like to carry your items, then will we
        forward all the details and necessary paperwork.
      </p>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
