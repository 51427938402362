<div *ngIf="entries" class="entriesContainer">
  <div class="row">
    <div class="col-1 text-left">&nbsp;</div>
    <div class="col-3 text-left underlineRow">Name (Age)</div>
    <div class="col-2 text-center underlineRow">Date of Death</div>
    <div class="col-1 text-left underlineRow">Cause</div>
    <div class="col-1 text-center underlineRow">Points</div>
    <div class="col-1 text-left underlineRow">Bonus</div>
    <div class="col-2 text-left underlineRow">Notes</div>
    <div class="col-1 text-left">&nbsp;</div>
  </div>
  <div *ngFor="let entry of entries" class="line">
    <div
      class="row"
      [ngClass]="{ mt10: entry.idx > competition.maxEntries }"
      *ngIf="entry.idx <= competition.maxEntries + competition.maxAlternates"
    >
      <div class="col-1 text-center">
        {{ entry.isAlternate ? "Alt" : entry.idx }}
      </div>
      <div class="col-3 text-left">
        <span
          *ngIf="entry.people.wikiURL !== ''"
          placement="right"
          ngbTooltip="Click to open a tab on wikipedia"
        >
          <a [href]="entry.people.wikiURL" style="color: #ccc" target="_new">{{
            entry.name
          }}</a>
        </span>
        <span *ngIf="entry.people.wikiURL === ''">
          {{ entry.name }}
        </span>
        <span *ngIf="entry.people.dateOfBirth">
          ({{
            entry.people.status === ePeopleStatus.NEW ? "New" : entry.age
          }})</span
        >
      </div>
      <div
        *ngIf="entry.people.status === ePeopleStatus.ALIVE"
        class="col-2 text-center a-link"
        (click)="deadRequest(entry)"
      >
        <span
          placement="right"
          ngbTooltip="If you believe this celebrity is dead, click here and we'll verify the information."
          >Dead?</span
        >
      </div>
      <div
        *ngIf="entry.people.status === ePeopleStatus.DEAD"
        class="col-2 text-center"
      >
        {{ entry.dateOfDeath | date }}
      </div>
      <div
        *ngIf="
          entry.people.status === ePeopleStatus.PENDING ||
          entry.people.status === ePeopleStatus.PENDING_DEAD
        "
        class="col-2 text-center"
      >
        {{ entry.people.status }}
      </div>
      <div
        *ngIf="entry.people.status === ePeopleStatus.NEW"
        class="col-2 text-center"
      >
        Awaiting Admin
      </div>
      <div class="col-1 text-left">{{ entry.causeOfDeath }}</div>
      <div class="col-1 text-center">
        {{ entry.points !== 0 ? entry.points : "" }}
      </div>
      <div class="col-1 text-left">
        {{ entry.bonus !== 0 ? "$" + entry.bonus.toString() : "" }}
      </div>
      <div class="col-2 text-left" [innerHtml]="splitNotes(entry.notes)"></div>
      <div class="col-1 text-left">
        <span
          *ngIf="isEntryActive()"
          (click)="deleteEntry(entry.id)"
          placement="right"
          ngbTooltip="Delete entry"
          class="delete"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
    </div>
    <div
      class="row"
      [ngClass]="{
        bonuses:
          entry.idx === competition.maxEntries + competition.maxAlternates + 1
      }"
      *ngIf="entry.idx > competition.maxEntries + competition.maxAlternates"
    >
      <div class="col-7 text-center">&nbsp;</div>
      <div class="col-1 text-center">
        {{ entry.points !== 0 ? entry.points : "" }}
      </div>
      <div class="col-1 text-left">
        {{ entry.bonus !== 0 ? "$" + entry.bonus.toString() : "" }}
      </div>
      <div class="col-2 text-left" [innerHtml]="splitNotes(entry.notes)"></div>
      <div class="col-1 text-left">&nbsp;</div>
    </div>
  </div>
  <div class="row">
    <div class="col-1 text-left">&nbsp;</div>
    <div class="col-3 text-left">&nbsp;</div>
    <div class="col-2 text-center">&nbsp;</div>
    <div class="col-1 text-left upperpadding">Totals</div>
    <div class="col-1 text-center upperborder">{{ totalPoints }} pts</div>
    <div class="col-1 text-left upperborder">
      ${{ totalPrizes }} <span *ngIf="totalPrizes !== 0">*</span>
    </div>
    <div class="col-2 text-left">&nbsp;</div>
    <div class="col-1 text-left">&nbsp;</div>
  </div>
  <div *ngIf="competitionList">
    <div class="row upperborder">
      <div class="col-12 text-center mt10">
        If you'd like to view any of the other competitions that you've entered,
        click on one of the lines below.
      </div>
    </div>
    <div class="row">
      <div class="col-3 text-center underlineRow">Competition Name</div>
      <div class="col-3 text-center underlineRow">Start Date</div>
      <div class="col-3 text-center underlineRow">End Date</div>
      <div class="col-3 text-center underlineRow">Status</div>
    </div>
    <div
      *ngFor="let competition of competitionList"
      class="line"
      [ngClass]="{ hand: competition.status !== 'Not Started' }"
    >
      <div class="row" (click)="reNavigate(competition)">
        <div class="col-3 text-center">{{ competition.name }}</div>
        <div class="col-3 text-center">{{ competition.startDate | date }}</div>
        <div class="col-3 text-center">{{ competition.endDate | date }}</div>
        <div class="col-3 text-center">{{ competition.status }}</div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="competitionStatus === 'Active'">
    <div class="col-12 text-center mt10">
      * - Bonus awards that are marked as "shared" indicate that multiple people
      won the award. The winner of the whole award will be picked at random from
      the list of winners. Awards that are marked as "split" indicate that
      multiple people won the award and that the award will be split equally
      between the contestants. Some awards, like the Grim Reaper award, are not
      final until the end of the competition.
    </div>
  </div>
</div>
<ng-template #markDeleted let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Request celebrity death</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('closed')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        If you believe {{ selectPerson.name }} is dead click the button below
        and we'll verify, thank you for letting us know!
      </div>
    </div>
    <div class="row text-center mt-10">
      <div class="col-12 text-center">
        <button
          type="button"
          class="btn btn-success request"
          (click)="deadRequestConfirm()"
        >
          Request Verification
        </button>
      </div>
    </div>
  </div>
</ng-template>
