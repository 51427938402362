export class Competition {
  constructor(
    public id: number,
    public name: string,
    public code: string,
    public startDate: Date,
    public endDate: Date,
    public entryEndDate: Date,
    public maxEntries: number,
    public maxAlternates: number
  ) {
    this.id = 0;
    this.name = '';
    this.code = '';
    this.startDate = new Date('1970-01-01');
    this.endDate = new Date('1970-01-01');
    this.entryEndDate = new Date('1970-01-01');
    this.maxEntries = 0;
    this.maxAlternates = 0;
  }
}

export class CompetitionCreateDTO {
  constructor(
    public name: string,
    public code: string,
    public startDate: Date,
    public endDate: Date,
    public entryEndDate: Date,
    public maxEntries: number,
    public maxAlternates: number
  ) {}
}

export class CompetitionUpdateDTO {
  constructor(
    public name: string,
    public code: string,
    public startDate: Date,
    public endDate: Date,
    public entryEndDate: Date,
    public maxEntries: number,
    public maxAlternates: number
  ) {}
}

export class CompetitionDisplay {
  constructor(
    public id: number,
    public idx: number,
    public name: string,
    public code: string,
    public startDate: Date,
    public endDate: Date,
    public entryEndDate: Date,
    public maxEntries: number,
    public maxAlternates: number,
    public status?: string
  ) {}
}
