import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-easter2022',
  templateUrl: './easter2022.component.html',
  styleUrls: ['./easter2022.component.css'],
})
export class Easter2022Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
