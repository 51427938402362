import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormModule } from '@coreui/angular';
import { Competition } from '../../../../models/competition';
import { Contestant } from '../../../../models/contestant';
import { EntriesDisplay } from '../../../../models/entries';
import { EmailService } from '../../../../services/email.service';

@Component({
  selector: 'app-ghoul-pool-email-entries',
  standalone: true,
  imports: [CommonModule, FormModule, RouterModule],
  templateUrl: './ghoul-pool-email-entries.component.html',
  styleUrl: './ghoul-pool-email-entries.component.scss',
})
export class GhoulPoolEmailEntriesComponent {
  @Input()
  competition: Competition;

  @Input()
  contestant: Contestant;

  @Input()
  entries: Array<EntriesDisplay>;

  public emailDisabled = false;

  constructor(private emailService: EmailService) {}

  emailEntry() {
    const toAddress = this.contestant.email;
    const subject = 'Your entries for ' + this.competition.name;
    let body = '<html>';
    body += '<head>';
    body += '<title>' + subject + '</title>';
    body += '</head>';
    body +=
      '<body style="background-color: black; font-family: Verdana; font-size: 1rem; color: #ccc; margin: 40px 40px; text-align: center;">';
    body += '<div style="width: 100%; max-width: 800px; height: 100%">';
    body += '<div>';
    body +=
      '<img src="https://www.ghoulishmortals.com/assets/images/gm-logo-new.png" alt="">';
    body += '</div>';
    body += '<div style="margin-top: 30px;">';
    body +=
      'Thank you for making your entries for ' + this.competition.name + '. ';
    body += 'Below are the entries you made. You have until ';
    body += new Date(this.competition.entryEndDate).toLocaleDateString();
    body += ' to make your adjustments.<br>';
    body +=
      'Check out your entries online to see updates for celebrity deaths and subsequent points and bonus awards.<br>';
    body += 'For a quick link to your online entries click ';
    body +=
      '<a href="https://www.ghoulishmortals.com/ghoulpoolEntry?email=' +
      this.contestant.email +
      '">here</a>.';
    body += '</div>';
    body += '<div style="margin-top: 20px;">';
    body += '<table width="100%">';
    body += '<tr>';
    body += '<td width="5%" style="border-bottom: 2px solid #333;">&nbsp;</td>';
    body +=
      '<td width="30%" style="border-bottom: 2px solid #333;">Name (Age)</td>';
    body +=
      '<td width="15%" style="border-bottom: 2px solid #333;">Date of Death</td>';
    body += '<td width="20%" style="border-bottom: 2px solid #333;">Cause</td>';
    body += '<td width="5%" style="border-bottom: 2px solid #333;">Points</td>';
    body += '<td width="25%" style="border-bottom: 2px solid #333;">Bonus</td>';
    body += '</tr>';

    let idx = 0;
    //  loop through the entries
    this.entries.forEach((entry) => {
      idx += 1;
      body += '<tr>';
      body += '<td>' + idx.toString() + '</td>';
      body += '<td>' + entry.name + ' (' + entry.age.toString() + ')' + '</td>';
      const dod =
        entry.dateOfDeath === null
          ? ''
          : new Date(entry.dateOfDeath).toLocaleDateString();
      body += '<td>' + dod + '</td>';
      body += '<td>' + entry.causeOfDeath + '</td>';
      body += '<td>' + entry.points + '</td>';
      body += '<td>' + entry.bonus + '</td>';
      body += '</tr>';
    });

    body += '</table>';
    body += '</div>';

    body += '</div>';
    body += '</body>';
    body += '</html>';

    this.emailService.send(toAddress, subject, body, true);
  }
}
