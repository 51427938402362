import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, Observable, of, throwError } from 'rxjs';
import {
  People,
  PeopleCreateDTO,
  PeopleStatus,
  PeopleUpdateDTO,
} from '../models/people';

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  private _url: string;
  private _options: any;

  constructor(private http: HttpClient) {
    this._url = `${environment.ghoulPoolApi}people`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._options = { headers: headers };
  }

  public get defaultPeople(): People {
    return new People(
      0,
      '',
      '',
      new Date('1970-01-01'),
      new Date('1970-01-01'),
      '',
      '',
      '',
      PeopleStatus.ALIVE,
      ''
    );
  }

  getPeopleSearch(
    search: string,
    top: number,
    showDeceased: number
  ): Observable<Array<People>> {
    if (search !== '') {
      const url = this._url + '/' + encodeURIComponent(search);
      let q = '';
      if (top) {
        q += '?top=' + top.toString();
      }
      if (showDeceased || showDeceased > -1) {
        q += q === '' ? '?' : '&';
        q += 'showDeceased=' + showDeceased.toString();
      }
      return this.http.get<Array<People>>(url + q).pipe(
        catchError((error) => {
          return throwError(() => new Error(error));
        })
      );
    } else {
      return of<Array<People>>([]);
    }
  }

  getPeopleById(id: number): Observable<People> {
    const url = this._url + '/id';
    return this.http.get<People>(url + '/' + id.toString()).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  create(people: PeopleCreateDTO): Observable<any> {
    const url = this._url;
    return this.http.post(url, people, this._options);
  }

  update(id: number, people: PeopleUpdateDTO): Observable<any> {
    const url = this._url + '/' + id.toString();
    return this.http.put(url, people, this._options);
  }

  calcAge(dateOfBirth: Date | null, dateOfDeath: Date | null): number {
    const date1 = dateOfDeath ? new Date(dateOfDeath).getTime() : Date.now();
    const date2 = dateOfBirth
      ? new Date(dateOfBirth).getTime()
      : new Date('1970-01-01').getTime();
    const timeDiff = Math.abs(date1 - date2);
    const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    return age;
  }
}
