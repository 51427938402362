<div class="container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <h1>Privacy Policy</h1>
      <p>
        Ghoulish Mortlas is committed to protecting your privacy. We use the
        information we collect to assist us in processing orders or replying to
        your inquiries. Please read on for more details about our privacy
        policy.
      </p>
      <p>
        <strong>The Information We Collect</strong>
      </p>
      <p>
        Ghoulish Mortlas collects from you the necessary information to process
        orders for our products or answer your online questions.
      </p>
      <p>
        <strong>Spam Email and Use of Email Addresses</strong>
      </p>
      <p>
        We make every effort to minimize the amount of email correspondence you
        receive from us. We do not share or sell your email address to any third
        parties.
      </p>
      <p>
        <strong>Site Security</strong>
      </p>
      <p>
        To ensure your security and order satisfaction, we employ industry
        leading security by partnering with Paypal to offer safe and secure
        credit card transactions for our customers.
      </p>
      <p>
        Paypal manages the complex routing of sensitive customer information.
        The company adheres to strict industry standards for payment processing,
        including:
      </p>
      <ul>
        <li>
          128-bit Secure Sockets Layer (SSL) technology for secure Internet
          Protocol (IP) transactions.
        </li>
        <li>
          Industry leading encryption hardware and software methods and security
          protocols to protect customer information.
        </li>
        <li>
          Compliance with the Payment Card Industry (PCI) Data Security
          Standard.
        </li>
      </ul>
      <p>
        For additional information regarding the privacy of your sensitive
        cardholder data, please read the Paypal Security assurance page at:
        https://www.paypal.com/us/webapps/mpp/paypal-safety-and-security
      </p>
      <p>
        <strong>Use of Cookies</strong>
      </p>
      <p>
        Ghoulish Mortals uses cookies to enhance your shopping and browsing
        experience and keep track of your order information. The cookies we use
        DO NOT store any personal information such as your e-mail address,
        street address or phone number.
      </p>
      <p>
        <strong>Do you still have questions?</strong>
      </p>
      <p>
        If you have additional questions or wish to further discuss details of
        our privacy policy, please feel free to email us at
        <a href="mailto:info@ghoulishmortals.com"
          >info&#64;ghoulishmortals.com</a
        >.
      </p>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
