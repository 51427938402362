import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Email } from '../models/email';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private _url: string;
  private _options: any;

  constructor(private http: HttpClient) {
    this._url = `${environment.ghoulPoolApi}email-mgr`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._options = { headers: headers };
  }

  create(emailMessage: Email): Observable<any> {
    const url = this._url;
    return this.http.post(url, emailMessage, this._options);
  }

  send(toAddress: string, subject: string, body: string, isHTML: boolean) {
    console.log('Sending email to ' + toAddress + ' with subject ' + subject);
    this.create(
      new Email(
        'ghoulpool-mail@ghoulishmortals.com',
        toAddress,
        subject,
        body,
        isHTML,
        'info@ghoulishmortals.com'
      )
    ).subscribe((result) => {
      console.log(result);
    });
  }
}
