import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Competition,
  CompetitionCreateDTO,
  CompetitionUpdateDTO,
} from '../../../../models/competition';
import { NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CompetitionService } from '../../../../services/competition.service';
import { GlobalService } from '../../../../services/global.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-competition-edit',
  standalone: true,
  imports: [CommonModule, FormsModule, NgbDatepickerModule, RouterModule],
  templateUrl: './competition-edit.component.html',
  styleUrl: './competition-edit.component.scss',
})
export class CompetitionEditComponent implements OnInit {
  @Input()
  competition: Competition;

  @Output()
  returnCompetition: EventEmitter<Competition> = new EventEmitter();

  private competitionSave: Competition;
  public competitionLocal: Competition;
  public startDate: NgbDateStruct;
  public endDate: NgbDateStruct;
  public entryEndDate: NgbDateStruct;

  constructor(
    private activatedRoute: ActivatedRoute,
    private competitionService: CompetitionService,
    public globalService: GlobalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.globalService.adminSelectionId === 0 && !this.competition) {
        this.competition = new Competition(
          0,
          '',
          '',
          new Date(),
          new Date(),
          new Date(),
          0,
          0
        );
      }
      this.competitionSave = new Competition(
        this.competition.id,
        this.competition.name,
        this.competition.code,
        this.competition.startDate,
        this.competition.endDate,
        this.competition.entryEndDate,
        this.competition.maxEntries,
        this.competition.maxAlternates
      );
      this.competitionLocal = new Competition(
        this.competition.id,
        this.competition.name,
        this.competition.code,
        this.competition.startDate,
        this.competition.endDate,
        this.competition.entryEndDate,
        this.competition.maxEntries,
        this.competition.maxAlternates
      );
      this.startDate = this.extractDate(this.competition.startDate);
      this.endDate = this.extractDate(this.competition.endDate);
      this.entryEndDate = this.extractDate(this.competition.entryEndDate);
    }, 100);
  }

  extractDate(incomingDate: Date): any {
    if (incomingDate) {
      const d = new Date(incomingDate);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();
      return { year, month, day };
    } else {
      return null;
    }
  }

  saveCompetition() {
    // if we have an id then update, otherwise add.
    if (this.competition.id === 0) {
      const competitionCreate = new CompetitionCreateDTO(
        this.competition.name,
        this.competition.code,
        new Date(
          this.startDate.year,
          this.startDate.month - 1,
          this.startDate.day
        ),
        new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day),
        new Date(
          this.entryEndDate.year,
          this.entryEndDate.month - 1,
          this.entryEndDate.day
        ),
        this.competition.maxEntries,
        this.competition.maxAlternates
      );

      this.competitionService.create(competitionCreate).subscribe((result) => {
        const competition: Competition = result;
        this.globalService.adminSelectionId = competition.id;
        this.returnCompetition.emit(competition);
        this.globalService.adminType = 'view';
        this.setQueryParams();
      });
    } else {
      const competitionUpdate = new CompetitionUpdateDTO(
        this.competition.name,
        this.competition.code,
        new Date(
          this.startDate.year,
          this.startDate.month - 1,
          this.startDate.day
        ),
        new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day),
        new Date(
          this.entryEndDate.year,
          this.entryEndDate.month - 1,
          this.entryEndDate.day
        ),
        this.competition.maxEntries,
        this.competition.maxAlternates
      );

      this.competitionService
        .update(this.competition.id, competitionUpdate)
        .subscribe((result) => {
          const competition: Competition = result;
          this.returnCompetition.emit(competition);
          this.globalService.adminType = 'view';
          this.setQueryParams();
        });
    }
  }

  cancelEdit() {
    if (this.globalService.adminSelectionId === 0) {
      this.globalService.adminType = '';
    } else {
      this.globalService.adminType = 'view';
    }
    this.setQueryParams();
  }

  setQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.globalService.adminQueryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}
