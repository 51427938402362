<div class="adminContainer">
  <ul
    ngbNav
    #nav="ngbNav"
    class="nav-tabs"
    [(activeId)]="globalService.adminTab"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>Competitions</a>
      <ng-template ngbNavContent>
        <app-competition-view></app-competition-view>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Contestants</a>
      <!-- <ng-template ngbNavContent>
        <app-contestants-view></app-contestants-view>
      </ng-template> -->
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>Entries</a>
      <ng-template ngbNavContent>Entries</ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink>People</a>
      <ng-template ngbNavContent>
        <app-people-view></app-people-view>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="adminContainerInner"></div>
</div>
