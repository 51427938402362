import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LeaderBoard } from '../models/competitionAwards';

@Injectable({
  providedIn: 'root',
})
export class CompetitionAwardsService {
  private _url: string;
  private _options: any;

  constructor(private http: HttpClient) {
    this._url = `${environment.ghoulPoolApi}competition-award`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._options = { headers: headers };
  }

  getLeaderboard(competitionId: number): Observable<Array<LeaderBoard>> {
    const url = this._url + '/leaderboard/' + competitionId.toString();
    return this.http.get<Array<LeaderBoard>>(url).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }
}
