import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-easter2020',
  templateUrl: './easter2020.component.html',
  styleUrls: ['./easter2020.component.css']
})
export class Easter2020Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
