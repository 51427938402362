<div class="container">
  <div class="row">
    <div class="col-8 text-right">
      If you would like to be emailed a copy of your entry click the button?
    </div>
    <div class="col-4">
      <button
        type="button"
        [disabled]="emailDisabled"
        class="btn btn-dark email"
        (click)="emailDisabled = true; emailEntry()"
      >
        Email
      </button>
    </div>
  </div>
</div>
