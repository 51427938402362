import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { People, PeopleSelection } from '../../../../models/people';
import { PeopleService } from '../../../../services/people.service';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { FormsModule } from '@angular/forms';
import { WikiSearchService } from '../../../../services/wiki-search.service';

@Component({
  selector: 'app-ghoul-pool-people-lookup',
  standalone: true,
  imports: [CommonModule, FormsModule, NgbModule, RouterModule],
  templateUrl: './ghoul-pool-people-lookup.component.html',
  styleUrl: './ghoul-pool-people-lookup.component.scss',
})
export class GhoulPoolPeopleLookupComponent {
  @Output()
  person: EventEmitter<PeopleSelection> = new EventEmitter();

  @ViewChild('personModal', { static: false }) private personModal: any;
  @ViewChild('newPersonModal', { static: false }) private newPersonModal: any;

  private searching = false;
  public searchFailed = false;
  public personSelection: any;
  public wikiSearch: any;
  public people: Array<People> = [];

  constructor(
    private peopleService: PeopleService,
    private modalService: NgbModal,
    private wikiSearchService: WikiSearchService
  ) {}

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.peopleService.getPeopleSearch(term, 10, 0).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  displayPeopleSearchResultValue(value: any) {
    const timeDiff = Math.abs(
      Date.now() - new Date(value.dateOfBirth).getTime()
    );
    const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    let ret = value.knownAs + ' (' + age.toString() + ')';
    ret += value.dateOfDeath ? ' (Deceased)' : '';

    return ret;
  }

  inputPeopleSearchResultValue(value: any) {
    if (value.knownAs) {
      return value.knownAs;
    } else {
      return value;
    }
  }

  personSelected(person: People) {
    const personInfo = new PeopleSelection(
      person.id,
      person.name,
      person.wikiURL
    );
    this.person.emit(personInfo);

    this.personSelection = '';
    this.modalService.dismissAll();
  }

  addNewPerson() {
    // user elected to add a new person, so close the old modal and open the newPersonModal
    this.modalService.dismissAll();
    this.modalService.open(this.newPersonModal, {
      centered: true,
      size: 'lg',
      windowClass: 'dark-modal',
    });
    this.wikiSearchService.search(this.personSelection).subscribe((results) => {
      this.wikiSearch = results;
    });
  }

  addPerson() {
    if (this.personSelection.name) {
      this.person.emit(this.personSelection);
      this.personSelection = '';
    } else {
      this.peopleService
        .getPeopleSearch(this.personSelection, 10, -1)
        .subscribe((results) => {
          this.people = results;
          if (this.people.length === 0) {
            this.addNewPerson();
          } else {
            this.modalService.open(this.personModal, {
              centered: true,
              size: 'lg',
              windowClass: 'dark-modal',
            });
          }
        });
    }
  }

  getWikiURL(name: string): string {
    return 'https://en.wikipedia.org/wiki/' + name.replace(' ', '_');
  }

  wikiSelected(name: string) {
    const wikiInfo = new PeopleSelection(
      -1,
      this.personSelection,
      this.getWikiURL(name)
    );
    this.person.emit(wikiInfo);
    this.personSelection = '';
    this.modalService.dismissAll();
  }

  calculateAge(person: People): number {
    return this.peopleService.calcAge(person.dateOfBirth, person.dateOfDeath);
  }
}
