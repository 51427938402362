<div class="slider-container">
  <c-carousel
    (itemChange)="onItemChange($event)"
    [interval]="8000"
    transition="slide"
  >
    <c-carousel-inner>
      <c-carousel-item *ngFor="let slide of slides">
        <img
          [src]="slide.src"
          alt="{{ slide.title }}"
          class="d-block w-100"
          loading="lazy"
        />
      </c-carousel-item>
    </c-carousel-inner>
  </c-carousel>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-12 text-center address">
      <a
        href="https://goo.gl/maps/zNzybbu67Yz"
        target="_blank"
        rel="noopener noreferrer"
        >228 W Main St, St Charles, IL 60174</a
      >
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-12 text-center hours-title">WINTER HOURS</div>
  </div>
  <div class="row">
    <div class="col-lg-3 text-center days">
      <span class="days">MON</span><br />
      <span class="hours">CLOSED</span><br />
    </div>
    <div class="col-lg-3 text-center days">
      <span class="days">TUE - THU</span><br />
      <span class="hours">12PM - 8PM</span><br />
    </div>
    <div class="col-lg-3 text-center days">
      <span class="days">FRI - SAT</span><br />
      <span class="hours">12PM - 10PM</span><br />
    </div>
    <div class="col-lg-3 text-center days">
      <span class="days">SUN</span><br />
      <span class="hours">12PM - 5PM</span><br />
    </div>
  </div> -->
  <div class="row">
    <div class="col-md-12 text-center hours-title">YEAR ROUND HOURS</div>
  </div>
  <div class="row">
    <div class="col-lg-6 text-center days">
      <span class="days">SUN - THU</span><br />
      <span class="hours">12PM - 9PM</span><br />
    </div>
    <div class="col-lg-6 text-center days">
      <span class="days">FRI - SAT</span><br />
      <span class="hours">12PM - 10PM</span><br />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center about">
      <p>
        Come visit Chicagoland’s fiendishly fun retail monster and horror store,
        oozing with creeptastic products from spooky artist and vendors.
      </p>
      <p>
        The store is a unique destination not to be missed, allowing fans of the
        genre to wander through themed sections to find art prints, sculpture,
        props, books, dolls, plushies, jewelry, stickers, patches, pins and
        quirky novelties.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center social-media">
      <a
        href="https://www.facebook.com/ghoulishmortals/"
        title="Facebook"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg viewBox="0 0 30 30">
          <path
            id="facebook"
            fill="#ffffff"
            d="M17.252,11.106V8.65c0-0.922,0.611-1.138,1.041-1.138h2.643V3.459l-3.639-0.015
          c-4.041,0-4.961,3.023-4.961,4.961v2.701H10v4.178h2.336v11.823h4.916V15.284h3.316l0.428-4.178H17.252z"
          />
        </svg>
      </a>
      <a
        href="https://www.instagram.com/ghoulishmortalschicago/"
        title="Instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg viewBox="0 0 30 30">
          <path
            id="instagram"
            fill="#ffffff"
            d="M22.107,3.415H7.893c-2.469,0-4.479,2.007-4.479,4.477v4.73v9.486c0,2.469,2.01,4.479,4.479,4.479h14.215
          c2.469,0,4.479-2.01,4.479-4.479v-9.486v-4.73C26.586,5.421,24.576,3.415,22.107,3.415 M23.393,6.086l0.512-0.004v0.511v3.416
          l-3.916,0.014l-0.012-3.928L23.393,6.086z M11.693,12.622c0.742-1.028,1.945-1.7,3.307-1.7s2.564,0.672,3.307,1.7
          c0.484,0.67,0.771,1.49,0.771,2.379c0,2.248-1.828,4.078-4.078,4.078c-2.248,0-4.078-1.83-4.078-4.078
          C10.922,14.112,11.211,13.292,11.693,12.622 M24.328,22.107c0,1.225-0.994,2.219-2.221,2.219H7.893
          c-1.225,0-2.219-0.994-2.219-2.219v-9.486h3.459C8.832,13.356,8.664,14.159,8.664,15c0,3.494,2.842,6.335,6.336,6.335
          s6.336-2.842,6.336-6.335c0-0.842-0.17-1.645-0.467-2.379h3.459V22.107z"
          />
        </svg>
      </a>
      <a
        href="https://twitter.com/GhoulishMortals"
        title="Twitter"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg viewBox="0 0 512 512">
          <path
            id="twitter"
            fill="#ffffff"
            d="M419.6 168.6c-11.7 5.2-24.2 8.7-37.4 10.2 13.4-8.1 23.8-20.8 28.6-36 -12.6 7.5-26.5 12.9-41.3 15.8 -11.9-12.6-28.8-20.6-47.5-20.6 -42 0-72.9 39.2-63.4 79.9 -54.1-2.7-102.1-28.6-134.2-68 -17 29.2-8.8 67.5 20.1 86.9 -10.7-0.3-20.7-3.3-29.5-8.1 -0.7 30.2 20.9 58.4 52.2 64.6 -9.2 2.5-19.2 3.1-29.4 1.1 8.3 25.9 32.3 44.7 60.8 45.2 -27.4 21.4-61.8 31-96.4 27 28.8 18.5 63 29.2 99.8 29.2 120.8 0 189.1-102.1 185-193.6C399.9 193.1 410.9 181.7 419.6 168.6z"
          />
        </svg>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center bottom-gap">
      <div
        class="fb-page"
        data-href="https://www.facebook.com/ghoulishmortals/"
        data-tabs="timeline"
        data-width="800"
        data-height="700"
        data-small-header="true"
        data-adapt-container-width="true"
        data-hide-cover="true"
        data-show-facepile="false"
      >
        <blockquote
          cite="https://www.facebook.com/ghoulishmortals/"
          class="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/ghoulishmortals/"
            >Ghoulish Mortals</a
          >
        </blockquote>
      </div>
    </div>
  </div>
</div>
