import { Component } from '@angular/core';

@Component({
  selector: 'app-easter2025',
  standalone: true,
  imports: [],
  templateUrl: './easter2025.component.html',
  styleUrl: './easter2025.component.scss'
})
export class Easter2025Component {

}
