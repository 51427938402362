<div class="row title">
  <div class="col-12 text-center">Competition Maintenance</div>
</div>
<div *ngIf="globalService.adminType === ''">
  <div class="row searchLine">
    <div class="col-6 text-left">
      <label for="competitionSearch">Search :</label>
      <input
        id="competitionSearch"
        type="text"
        class="competitionSearch"
        (keyup)="filterData()"
        [(ngModel)]="competitionSearch"
        placeholder="Competition search"
      />
    </div>
    <div class="col-4">&nbsp;</div>
    <div class="col-2">
      <button
        type="button"
        class="btn btn-success submit"
        (click)="addCompetition()"
      >
        Add Competition
      </button>
    </div>
  </div>
  <div class="table-fixed-height">
    <table class="table table-striped" aria-describedby="Competition List">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" sortable="name" (sort)="onSort($event)">Name</th>
          <th scope="col" sortable="startDate" (sort)="onSort($event)">
            Start Date
          </th>
          <th scope="col" sortable="endDate" (sort)="onSort($event)">
            End Date
          </th>
          <th scope="col" sortable="entryEndDate" (sort)="onSort($event)">
            Entry End
          </th>
          <th
            scope="col"
            sortable="maxEntries"
            (sort)="onSort($event)"
            class="text-center"
          >
            Max Entries
          </th>
          <th
            scope="col"
            sortable="maxAlternates"
            (sort)="onSort($event)"
            class="text-center"
          >
            Max Alt.
          </th>
        </tr>
      </thead>
      <tbody *ngIf="competitionsDisplay">
        <tr *ngFor="let competition of competitionsDisplay" class="onHover">
          <th scope="row" (click)="viewCompetition(competition.id)">
            {{ competition.idx }}
          </th>
          <td (click)="viewCompetition(competition.id)">
            {{ competition.name }}
          </td>
          <td (click)="viewCompetition(competition.id)">
            {{ competition.startDate | date }}
          </td>
          <td (click)="viewCompetition(competition.id)">
            {{ competition.endDate | date }}
          </td>
          <td (click)="viewCompetition(competition.id)">
            {{ competition.entryEndDate | date }}
          </td>
          <td (click)="viewCompetition(competition.id)" class="text-center">
            {{ competition.maxEntries | number }}
          </td>
          <td (click)="viewCompetition(competition.id)" class="text-center">
            {{ competition.maxAlternates | number }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="d-flex justify-content-between p-2 ml-2">
      <ngb-pagination
        [collectionSize]="competitionsFiltered.length"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refreshCompetition()"
        [maxSize]="5"
        [rotate]="true"
        [ellipses]="false"
        [boundaryLinks]="true"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
<div *ngIf="globalService.adminType !== ''" class="competition-container">
  <div *ngIf="globalService.adminType === 'view'">
    <app-view-competition
      [competition]="competitionSelected"
    ></app-view-competition>
  </div>
  <div *ngIf="'edit|new'.indexOf(globalService.adminType) !== -1">
    <app-competition-edit
      (returnCompetition)="returnCompetition($event)"
      [competition]="competitionSelected"
    ></app-competition-edit>
  </div>
  <div *ngIf="globalService.adminSelectionId !== 0" class="subContainer">
    <ul
      ngbNav
      #nav="ngbNav"
      class="nav-tabs"
      [(activeId)]="globalService.adminSubTab"
    >
      <li [ngbNavItem]="1" (click)="setSubTab()">
        <a ngbNavLink>Prizes</a>
        <ng-template ngbNavContent>
          <app-view-competition-prizes></app-view-competition-prizes>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" (click)="setSubTab()">
        <a ngbNavLink>Contestants</a>
        <ng-template ngbNavContent>
          <app-view-competition-contestants></app-view-competition-contestants>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
