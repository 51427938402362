<div class="container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <h1>Terms And Conditions</h1>
      <p>
        The use of this site is governed by the policies, terms and conditions
        set forth below. Please read them carefully.
      </p>
      <p>
        <strong
          >Your use of this site indicates your acceptance of these terms and
          conditions.
        </strong>
      </p>
      <p>
        Your placement of an order indicates your acceptance of these terms and
        conditions. Ghoulish Mortals reserves the right to change and amend this
        terms and conditions at any time without notice to you. Such changes and
        amendments shall become effective immediately upon the posting of same
        to the ghoulishmortals.com website.
      </p>
      <p>
        <strong>Copyright Information</strong>
      </p>
      <p>
        This site is owned and operated by Ghoulish Mortals. Unless otherwise
        specified, all materials appearing on this site, including the text,
        site design, logos, graphics, icons, and images, as well as the
        selection, assembly and arrangement thereof, are the sole property of
        Ghoulish Mortals.
      </p>
      <p>
        All software used on the site is the sole property of Ghoulish Mortals,
        or those supplying the software. You may use the content of this site
        only for the purpose of shopping on this site or placing an order on
        this site and for no other purpose. No materials from this site may be
        copied, reproduced, modified, republished, uploaded, posted,
        transmitted, or distributed in any form or by any means without prior
        written permission. The exception to this is using the provided Pin It
        button where indicated to pin to Pinterest.
      </p>
      <p>
        All rights not expressly granted herein are reserved. Any unauthorized
        use of the materials appearing on this site may violate copyright,
        trademark and other applicable laws and could result in criminal or
        civil penalties.
      </p>
      <p>
        <strong>Disclaimers, Warranty and Return Policy</strong>
      </p>
      <p>
        All of Ghoulish Mortals products are returnable within 7 days of the
        date of delivery/purchase provided they are received back in sellable
        condition. If the product was received damaged, then please keep
        packaging and contact us, providing a picture of the damaged product and
        box so that we can take it up with the shipping company while we are
        processing your replacement.
      </p>
      <p>
        Please see our specific return policy
        <a href="#" routerLink="/returns">here</a>.
      </p>
      <p>
        <strong>Typographical Errors</strong>
      </p>
      <p>
        In the event a product is listed at an incorrect price due to
        typographical error or error in pricing information received from our
        suppliers, Ghoulish Mortals shall have the right to refuse or cancel any
        orders placed for product(s) listed at the incorrect price.
      </p>
      <p>
        Ghoulish Mortals shall have the right to refuse or cancel any such
        orders whether or not the order has been confirmed and your credit card
        charged. If your credit card has already been charged for the purchase
        and your order is canceled, Ghoulish Mortals shall immediately issue a
        credit to your credit card account in the amount of the incorrect price
        and email you to let you know, giving you the option of ordering again.
      </p>
      <p>
        <strong>Compliance with Law and Fraud Protection</strong>
      </p>
      <p>
        We may collect and disclose any information, including personally
        identifiable information, we deem necessary, in our sole discretion, to
        comply with any applicable law, regulation, legal process or
        governmental request. We may collect and possibly share personally
        identifiable information and any other information available to us in
        order to investigate, prevent or take action regarding illegal
        activities, suspected fraud, situations involving potential threats to
        the physical safety of any personal or as otherwise required by law.
      </p>
      <p>
        <strong>Links</strong>
      </p>
      <p>
        This site may contain links to other sites on the Internet that are
        owned and operated by other vendors and social media companies that
        Ghoulish Mortals think you might have similarly interesting
        horror/monster content. You acknowledge that Ghoulish Mortals is not
        responsible for the operation of or content located on or through any
        such site.
      </p>
      <p>
        <strong>Communications</strong>
      </p>
      <p>
        If you provide Ghoulish Mortals with personally identifiable
        information, this information may be used to communicate to you
        information regarding Ghoulish Mortals and its products and services.
        For example, we may communicate to you with information regarding
        promotions, surveys, contests, new products or service updates.
      </p>
      <p>
        If you place an order with Ghoulish Mortals, we will use your personally
        identifiable information to confirm your order, prevent and detect fraud
        or abuse, respond to your requests or inquiries, provide you with other
        administrative information regarding your order and provide you with any
        other information we deem helpful or interesting to you.
      </p>
      <p>
        If you do not want to receive communications from Ghoulish Mortals you
        may elect to opt-out of receiving such communications, please email us
        at info&#64;ghoulishmortals.com with the word “unsubscribe” in the
        subject header.
      </p>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
