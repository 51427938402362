<div class="center-menu">
  <div class="navigation-line">
    <span class="navigation-item">
      <a
        href="#"
        routerLink="/"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
        >Home</a
      ></span
    >|<span class="navigation-item">
      <a href="https://ghoulishmortals.shop" target="_new">Shop</a></span
    >|<span class="navigation-item">
      <a
        href="#"
        routerLink="events"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
        >Events</a
      ></span
    >|<span class="navigation-item">
      <a
        href="#"
        routerLink="artists"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
        >Artists</a
      ></span
    >|<span class="navigation-item">
      <a
        href="#"
        routerLink="about"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
        >About Us</a
      ></span
    >|<span class="navigation-item">
      <a
        href="#"
        routerLink="contactus"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
        >Contact Us</a
      ></span
    >
  </div>
</div>
