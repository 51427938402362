import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GhoulPoolDeaths, GhoulPoolWinners } from '../../../models/ghoulpool';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ghoulpool2020',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './ghoulpool2020.component.html',
  styleUrl: './ghoulpool2020.component.scss',
})
export class Ghoulpool2020Component implements OnInit {
  public winners: Array<GhoulPoolWinners> = [];
  public deaths: Array<GhoulPoolDeaths> = [];

  ngOnInit() {
    this.buildGhoulPoolInfo();
  }

  buildGhoulPoolInfo() {
    this.winners = [
      {
        name: 'Name',
        deaths: 'Deaths',
        points: 'Points',
        pointTotal: '',
        bonuses: 'Bonues',
        header: true,
      },
      {
        name: 'Mark Gordon',
        deaths:
          'Terry Jones, Kenny Rogers (Random – 10pts), Bob Gibson (Random - 10pts), Alex Trebek',
        points: '43 + 34 + 10 + 21 + 10 + 125 = 243',
        pointTotal: '',
        bonuses: '$25 First To Die',
        header: false,
      },
      {
        name: 'Steve Clevenger',
        deaths: 'Terry Jones, Kirk Douglas, Alex Trebek',
        points: '43 + 12 + 125 = 180',
        pointTotal: '',
        bonuses: '$25 First To Die',
        header: false,
      },
      {
        name: 'Erica Orlowski-Liedberg ',
        deaths:
          'Kirk Douglas, Ruth Bader Gnsburg (Random – 10pts), Alex Trebek',
        points: '12 + 18 + 10 + 125 = 165',
        pointTotal: '',
        bonuses: '',
        header: false,
      },
      {
        name: 'John Derby Arndt',
        deaths: 'Kirk Douglas, Jerry Stiller, Alex Trebek',
        points: '12 + 23 + 125 = 160',
        pointTotal: '',
        bonuses: '',
        header: false,
      },
      {
        name: 'Amber Nugent',
        deaths: 'Kirk Douglas, Vera Lynn (Random – 10pts), Alex Trebek',
        points: '12 + 2 + 10 + 125 = 149',
        pointTotal: '',
        bonuses: '',
        header: false,
      },
      {
        name: 'Kevon Biksacky',
        deaths: 'Alex Trebek',
        points: '125',
        pointTotal: '',
        bonuses: '',
        header: false,
      },
      {
        name: 'Laura Fields',
        deaths:
          'Carl Reiner (Random – 10pts), Olivia de Haviland, Michael Lonsdale (Random – 10pts)',
        points: '17 + 10 + 11 + 16 + 10 = 64',
        pointTotal: '',
        bonuses: '',
        header: false,
      },
      {
        name: 'Katie Davis',
        deaths:
          'Olivia de Haviland (Dead of Night - 10pts), Sean Connery (Random – 10pts)',
        points: '11 + 15 + 10 = 36',
        pointTotal: '',
        bonuses: '$50 Halloween',
        header: false,
      },
      {
        name: 'Eli Kendra',
        deaths: 'Jerry Stiller',
        points: '23',
        pointTotal: '',
        bonuses: '',
        header: false,
      },
    ];
  }
}
