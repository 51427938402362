import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Competition } from '../../../models/competition';
import { LeaderBoard } from '../../../models/competitionAwards';
import { CompetitionAwardsService } from '../../../services/competition-awards.service';
import { CompetitionService } from '../../../services/competition.service';

@Component({
  selector: 'app-ghoulpool-result',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule],
  templateUrl: './ghoulpool-results.component.html',
  styleUrl: './ghoulpool-results.component.scss',
})
export class GhoulpoolResultsComponent implements OnInit {
  public competition: Competition;
  public leaderBoard: LeaderBoard[];
  public competitionStatus: string;

  private competitionCode: string;

  constructor(
    private competitionService: CompetitionService,
    private competitionAwardService: CompetitionAwardsService,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.router.queryParams.subscribe((params) => {
      if (params['code']) {
        this.competitionCode = params['code'];
        this.competitionService
          .getCompetitionByCode(this.competitionCode)
          .subscribe((results) => {
            this.competition = results;
            this.competitionStatus =
              this.competitionService.getCompetitionStatus(this.competition);
            this.getLeaderBoard(this.competition.id);
          });
      } else {
        this.competitionService.getActiveCompetition().subscribe((results) => {
          this.competition = results;
          this.competitionStatus = this.competitionService.getCompetitionStatus(
            this.competition
          );
          this.competitionCode = this.competition.code;
          this.getLeaderBoard(this.competition.id);
        });
      }
    });
  }

  getLeaderBoard(competitionId: number) {
    this.competitionAwardService
      .getLeaderboard(this.competition.id)
      .subscribe((r: any) => {
        this.leaderBoard = r;
      });
  }
}
