<div *ngIf="competition" class="container gm-border rounded">
  <div class="row">
    <div class="col-12 text-center">
      <h1>{{ competition.name + " (" + competitionStatus + ")" }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <span *ngIf="competitionStatus === 'Ended'">
        Congratulations to the winners of the {{ competition.name }} event. If
        you haven't already done so, please come into the store to pickup your
        Gift Certificate.
      </span>
      <span *ngIf="competitionStatus === 'Active'">
        The points shown below are not final until the end of the event. If you
        see any errors in your scores, please double check them on your own
        entry page, at
        <a href="../ghoulpoolEntry">www.GhoulishMortals.com/ghoulpoolEntry</a>.
        If you think there is an error, please email us at
        <a href="mailto: ghoulpool@ghoulishmortals.com"
          >ghoulpool&#64;ghoulishmortals.com</a
        >.
      </span>
      <span *ngIf="competitionStatus === 'Not Started'">
        The {{ competition.name }} event hasn't started yet, points will start
        to be awarded after it's official start on
        {{ competition.entryEndDate | date }}.
      </span>
    </div>
  </div>
  <div *ngIf="leaderBoard">
    <div class="row">
      <div class="col-3 text-left header">Name</div>
      <div class="col-3 text-left header">Deaths</div>
      <div class="col-2 text-left header">Points</div>
      <div class="col-1 text-left header">Total</div>
      <div class="col-3 text-left header">Bonuses</div>
    </div>
    <div
      class="row"
      *ngFor="let leader of leaderBoard; let i = index"
      [ngStyle]="{ 'background-color': i % 2 === 0 ? '#111' : '#000' }"
    >
      <div class="col-3 text-left">{{ leader.contestantName }}</div>
      <div *ngIf="leader.pointTotal !== 0" class="col-3 text-left">
        {{ leader.deaths }}
      </div>
      <div *ngIf="leader.pointTotal !== 0" class="col-2 text-left">
        {{ leader.points }}
      </div>
      <div *ngIf="leader.pointTotal !== 0" class="col-1 text-left">
        {{ leader.pointTotal }}
      </div>
      <div *ngIf="leader.pointTotal !== 0" class="col-3 text-left">
        {{ leader.bonuses }}
      </div>
      <div *ngIf="leader.pointTotal === 0" class="col-9 text-center">
        -- No awards --
      </div>
    </div>
  </div>
  <div *ngIf="!leaderBoard">
    <div class="row">
      <div class="col-12 text-center">
        <em class="fas fa-spinner fa-spin"></em>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!competition" class="container gm-border rounded">
  <div class="row">
    <div class="col-12">
      <br />
      The Ghoul Pool service is currently down. Please check back later. If you
      continue to have issues please reach out to
      <a href="mailto:ghoulpool@ghoulishmortals.com"
        >ghoulpool&#64;ghoulishmortals.com</a
      >. <br />&nbsp;<br />
    </div>
  </div>
</div>
