import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  Competition,
  CompetitionCreateDTO,
  CompetitionDisplay,
  CompetitionUpdateDTO,
} from '../models/competition';
import { catchError, Observable, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompetitionService {
  private _url: string;
  private _options: any;

  constructor(private http: HttpClient) {
    this._url = `${environment.ghoulPoolApi}competitions`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._options = { headers: headers };
  }

  public get defaultCompetition(): Competition {
    return new Competition(
      0,
      '',
      '',
      new Date('1970-01-01'),
      new Date('1970-01-01'),
      new Date('1970-01-01'),
      0,
      0
    );
  }

  getCompetitionStatus(c: Competition): string {
    const startDate = new Date(c.startDate);
    const endDate = new Date(c.endDate);
    const entryEndDate = new Date(c.entryEndDate);
    const today = new Date();
    let competitionStatus = '';

    if (today < entryEndDate) {
      competitionStatus = 'Not Started';
    }
    if (today > entryEndDate && today < endDate) {
      competitionStatus = 'Active';
    }
    if (today > endDate) {
      competitionStatus = 'Ended';
    }

    return competitionStatus;
  }

  getCompetitionSearch(search: string): Observable<Array<Competition>> {
    const url = this._url;
    return this.http.get<Array<Competition>>(url).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  getActiveCompetition(): Observable<Competition> {
    const url = this._url + '/active';
    return this.http.get<Competition>(url).pipe(
      tap((_) => console.log('fetched record')),
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  getCompetitionById(id: number): Observable<Competition> {
    const url = this._url + '/byid/' + id.toString();
    return this.http.get<Competition>(url).pipe(
      tap((_) => console.log('fetched record')),
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  getCompetitionByCode(code: string): Observable<Competition> {
    const url = this._url + '/bycode/' + code;
    return this.http.get<Competition>(url).pipe(
      tap((_) => console.log('fetched record')),
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  getCompetitionsByContestant(
    id: number
  ): Observable<Array<CompetitionDisplay>> {
    const url = this._url + '/contestant/' + id.toString();
    return this.http.get<Array<CompetitionDisplay>>(url).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  create(competition: CompetitionCreateDTO): Observable<any> {
    const url = this._url;
    return this.http.post(url, competition, this._options);
  }

  update(id: number, competition: CompetitionUpdateDTO): Observable<any> {
    const url = this._url + '/' + id.toString();
    return this.http.put(url, competition, this._options);
  }
}
