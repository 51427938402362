<div class="container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <h1 class="text-center">Easter Portrait Information</h1>
      <p>
        Please arrive for your appointment about 10 minutes beforehand just in
        case we are running ahead of schedule. Sessions with adults generally go
        faster than those with kids/pets, so depending on the appt before you,
        we might be able to take you in early. If you get here super early
        please hang out in the store. If you are running late, please call the
        store at 630-441-2920 and let Warwick know you are running late and he
        will text me.
      </p>
      <p>
        The sessions are being done above the store, accessed along the 3rd
        street glass stairwell door. Please wait at the bottom of the stairwell
        until I call you or you see the group before you exiting down the
        stairs. There is a bathroom at the top of the stairs should you need to
        use it. If you are bringing a pet, please build in time so that they can
        go potty before entering the store. Lincoln Park is only a short block
        West of the store, so theres definitely a nearby spot for it.
      </p>
      <p>
        If you are coming with your little ones, please remind them that Peter
        Rottentail is just my son Niles or our new employee Alora in a costume.
        That like everything else at the store, its all pretend and just meant
        to be fun. The actor will take off the costume head to breathe cool
        fresh air between sessions, so he/she will have it off as you enter the
        room. That way you can say hi, and if you have kids/pets, they can meet
        him too.
      </p>
      <p>
        The set is deliberately pastel and cutesy so it definitely presses the
        point about Peter Rottentail being the thing that is not like the
        others. My suggestions for clothing are to choose color coordinating
        outfits, not matching ones unless you really want. Having everyone in
        the same color scheme make the portraits more composed. I say this
        because if everyone is wearing greys and blacks, and one person is in
        bright red, then when anyone views your portraits then their eye will go
        straight to the person wearing red first. Also, I suggest two other
        things. Try to all dress for the same season. I know it may be snowing
        out that day, but try not to mix big heavy sweaters and boots and then
        tank tops and sandals in the same group. Also, please don’t purposely
        wear shirts that have lettering on them, as your posing will likely
        obscure the message. For example, wearing matching Easter shirts that
        say “Just chiilin’ with my peeps” might end up with your portrait having
        a shirt that states “Chill my pee”. Although that could be funny.
      </p>
      <p>
        If you need to reschedule, please do so online where you booked your
        session. If you must cancel, please please PLEASE try to let us know as
        far in advance as possible. Your session is refundable up to 24 hours
        before your session starts. If someone in your party wakes up sick, then
        we understand things happen, and are happy to hold your fee as store
        credit, less a $25 fee. Each year we have a waitlist, and there are only
        so many spots. If you are a no show without calling, then you SUCK and
        unless a coroner is involved, we keep your money. Again, if you are
        running late, just call the store at 630-441-2920 and my husband will
        let us know. Sometimes the appointment after you arrives early enough
        that we can flip flop the sessions, allowing you to be on time!
      </p>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
