import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  Competition,
  CompetitionDisplay,
} from '../../../../models/competition';
import { CompetitionService } from '../../../../services/competition.service';
import { GlobalService } from '../../../../services/global.service';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '../../../../components/checkbox/checkbox.component';
import { CommonModule } from '@angular/common';
import { NgbNavModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CompetitionEditComponent } from '../competition-edit/competition-edit.component';
import { ViewCompetitionComponent } from '../view-competition/view-competition.component';
import { ViewCompetitionContestantsComponent } from '../view-competition-contestants/view-competition-contestants.component';
import { ViewCompetitionPrizesComponent } from '../view-competition-prizes/view-competition-prizes.component';

@Component({
  selector: 'app-competition-view',
  standalone: true,
  imports: [
    CheckboxComponent,
    CommonModule,
    CompetitionEditComponent,
    FormsModule,
    NgbNavModule,
    NgbPaginationModule,
    RouterModule,
    ViewCompetitionComponent,
    ViewCompetitionContestantsComponent,
    ViewCompetitionContestantsComponent,
    ViewCompetitionPrizesComponent,
  ],
  templateUrl: './competition-view.component.html',
  styleUrl: './competition-view.component.scss',
})
export class CompetitionViewComponent implements OnInit {
  public competitions: Array<Competition> = [];
  public competitionsFiltered: Array<Competition> = [];
  public competitionsDisplay: Array<CompetitionDisplay> = [];
  public competitionSelected: Competition;
  public page = 1;
  public pageSize = 10;
  public competitionSearch: string;
  public toggleActive = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private competitionService: CompetitionService,
    public globalService: GlobalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.competitionSearch = '';
    this.getCompetitions();
  }

  getCompetitions() {
    this.competitionService.getCompetitionSearch('').subscribe((results) => {
      this.competitions = results;
      this.competitionsFiltered = this.competitions;
      this.refreshCompetition();

      if (this.globalService.adminSelectionId !== 0) {
        const _competitionSelected = this.competitions.find(
          (p) => p.id === this.globalService.adminSelectionId
        );
        if (_competitionSelected) {
          this.competitionSelected = _competitionSelected;
          this.globalService.adminSubTab = 1;
        }
        this.globalService.adminType =
          this.globalService.adminType === ''
            ? 'view'
            : this.globalService.adminType;
        this.setQueryParams();
      } else {
        this.globalService.adminTab = 1;
        this.globalService.adminSubTab = 0;
        this.globalService.adminSelectionId = 0;
        this.globalService.adminType = '';
        this.setQueryParams();
      }
    });
  }

  refreshCompetition() {
    const startIdx = (this.page - 1) * this.pageSize;
    const endIdx =
      startIdx + this.pageSize > this.competitionsFiltered.length
        ? this.competitionsFiltered.length
        : startIdx + this.pageSize;
    this.competitionsDisplay = [];
    for (let i = startIdx; i < endIdx; i++) {
      const competition = this.competitionsFiltered[i];
      const competitionDisplay = new CompetitionDisplay(
        competition.id,
        i + 1,
        competition.name,
        competition.code,
        competition.startDate,
        competition.endDate,
        competition.entryEndDate,
        competition.maxEntries,
        competition.maxAlternates
      );
      this.competitionsDisplay.push(competitionDisplay);
    }
  }

  filterData() {
    this.competitionsFiltered = this.competitions.filter((p) =>
      p.name.toLowerCase().includes(this.competitionSearch.toLowerCase())
    );
    this.refreshCompetition();
  }

  addCompetition() {
    this.globalService.adminSelectionId = 0;
    this.competitionSelected = this.competitionService.defaultCompetition;
    this.globalService.adminType = 'edit';
    this.setQueryParams();
  }

  viewCompetition(id: number) {
    this.globalService.adminSelectionId = id;
    const _competitionSelected = this.competitions.find((p) => p.id === id);
    this.competitionSelected = _competitionSelected
      ? _competitionSelected
      : this.competitionService.defaultCompetition;
    this.globalService.adminType = 'view';
    this.setQueryParams();
  }

  updateType(e: string) {
    this.globalService.adminType = e;
    this.setQueryParams();
  }

  onSort(e: any) {
    console.log(e);
  }

  setToggle(option: number, e: any) {
    if (option === 1) {
      this.toggleActive = e;
      this.filterData();
    }
  }

  returnCompetition(e: any) {
    this.refreshCompetition();
  }

  setSubTab() {
    this.setQueryParams();
  }

  setQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.globalService.adminQueryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}
