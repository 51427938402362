<div class="row searchLine">
  <div class="col-12 text-right">
    <button
      type="button"
      class="btn btn-success cancel"
      (click)="cancelEdit()"
      *ngIf="'edit|new'.indexOf(globalService.adminType) !== -1"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success submit"
      (click)="saveCompetition()"
      *ngIf="'edit|new'.indexOf(globalService.adminType) !== -1"
    >
      Save
    </button>
  </div>
</div>
<table class="table table-striped m-10">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Start Date</th>
      <th scope="col">End Date</th>
      <th scope="col">Entry End</th>
      <th scope="col" class="text-center w-120px">Max Entries</th>
      <th scope="col" class="text-center w-120px">Max Alt.</th>
    </tr>
  </thead>
  <tbody *ngIf="competition">
    <tr>
      <td class="align-middle">
        {{ competition.id === 0 ? "" : competition.id }}
      </td>
      <td>
        <input
          type="text"
          id="editName"
          [(ngModel)]="competition.name"
          class="edit"
        />
      </td>
      <td class="align-middle w-150px">
        <input
          type="text"
          id="editStartDate"
          [(ngModel)]="startDate"
          ngbDatepicker
          #d="ngbDatepicker"
          class="edit w-80"
        />
        <button
          class="btn btn-outline-secondary"
          (click)="d.toggle()"
          type="button"
        >
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </td>
      <td class="align-middle w-150px">
        <input
          type="text"
          id="editEndDate"
          [(ngModel)]="endDate"
          ngbDatepicker
          #d2="ngbDatepicker"
          class="edit w-80"
        />
        <button
          class="btn btn-outline-secondary"
          (click)="d2.toggle()"
          type="button"
        >
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </td>
      <td class="align-middle w-150px">
        <input
          type="text"
          id="editEntryEndDate"
          [(ngModel)]="entryEndDate"
          ngbDatepicker
          #d3="ngbDatepicker"
          class="edit w-80"
        />
        <button
          class="btn btn-outline-secondary"
          (click)="d3.toggle()"
          type="button"
        >
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </td>
      <td>
        <input
          type="text"
          id="editMaxEntries"
          [(ngModel)]="competition.maxEntries"
          class="edit text-center w-120px"
        />
      </td>
      <td>
        <input
          type="text"
          id="editMaxAlternates"
          [(ngModel)]="competition.maxAlternates"
          class="edit text-center w-120px"
        />
      </td>
    </tr>
  </tbody>
</table>
