import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Prizes, PrizesUpdateDTO } from '../../../../models/prizes';
import { GlobalService } from '../../../../services/global.service';
import { PrizesService } from '../../../../services/prizes.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-view-competition-prizes-edit',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule],
  templateUrl: './view-competition-prizes-edit.component.html',
  styleUrl: './view-competition-prizes-edit.component.scss',
})
export class ViewCompetitionPrizesEditComponent implements OnInit {
  @Input()
  prizes: Prizes;

  @Input()
  type: string;

  @Output()
  returnOption: EventEmitter<string> = new EventEmitter();

  @Output()
  returnPrizes: EventEmitter<Prizes> = new EventEmitter();

  private prizesSave: Prizes;
  public prizesLocal: Prizes;

  public spinning = false;
  public deleting = false;

  constructor(
    private globalService: GlobalService,
    private prizesService: PrizesService
  ) {}

  ngOnInit(): void {
    this.prizesSave = new Prizes(
      this.prizes.id,
      this.prizes.code,
      this.prizes.name,
      this.prizes.description,
      this.prizes.prize,
      this.prizes.type,
      this.prizes.option
    );
    this.prizesLocal = new Prizes(
      this.prizes.id,
      this.prizes.code,
      this.prizes.name,
      this.prizes.description,
      this.prizes.prize,
      this.prizes.type,
      this.prizes.option
    );
  }

  cancelDialog() {
    this.returnOption.emit('cancel');
  }

  updatePrizes() {
    if (this.changesMade()) {
      const prizesUpdate = new PrizesUpdateDTO(
        this.prizesLocal.code,
        this.prizesLocal.name,
        this.prizesLocal.description,
        this.prizesLocal.prize,
        this.prizesLocal.type,
        this.prizesLocal.option
      );

      console.log(prizesUpdate);

      // if we don't have a record id then this is a create
      if (this.prizesLocal.id === 0) {
        this.prizesService
          .create(this.globalService.adminSelectionId, prizesUpdate)
          .subscribe((result) => {
            const prizes: Prizes = result;
            this.returnPrizes.emit(prizes);
          });
      } else {
        this.prizesService
          .update(this.prizesLocal.id, prizesUpdate)
          .subscribe((result) => {
            const prizes: Prizes = result;
            this.returnPrizes.emit(prizes);
          });
      }
    }
  }

  changesMade(): boolean {
    let changes = false;

    changes = this.prizesLocal.name !== this.prizesSave.name ? true : changes;
    changes =
      this.prizesLocal.description !== this.prizesSave.description
        ? true
        : changes;
    changes = this.prizesLocal.prize !== this.prizesSave.prize ? true : changes;

    return changes;
  }

  deletePrizes(id: number) {
    this.prizesService.delete(id).subscribe((result) => {
      this.returnOption.emit('delete/' + id.toString());
    });
  }
}
