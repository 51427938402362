import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import { Prizes, PrizesCreateDTO, PrizesUpdateDTO } from '../models/prizes';

@Injectable({
  providedIn: 'root',
})
export class PrizesService {
  private _url: string;
  private _options: any;

  constructor(private http: HttpClient) {
    this._url = `${environment.ghoulPoolApi}prizes`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._options = { headers: headers };
  }

  public get defaultPrizes(): Prizes {
    return new Prizes(0, '', '', '', 0, 0, '');
  }

  getPrizesSearch(
    competitionId: number,
    search: string
  ): Observable<Array<Prizes>> {
    const url =
      this._url +
      '/' +
      competitionId.toString() +
      '/' +
      encodeURIComponent(search);
    return this.http.get<Array<Prizes>>(url).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  getPrizesById(id: number): Observable<Prizes> {
    const url = this._url + '/id';
    return this.http.get<Prizes>(url + '/' + id.toString()).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  create(competitionId: number, prizes: PrizesCreateDTO): Observable<any> {
    const url = this._url + '/' + competitionId.toString();
    return this.http.post(url, prizes, this._options);
  }

  update(id: number, prizes: PrizesUpdateDTO): Observable<any> {
    const url = this._url + '/' + id.toString();
    return this.http.put(url, prizes, this._options);
  }

  delete(id: number): Observable<any> {
    const url = this._url + '/' + id.toString();
    return this.http.delete(url, this._options);
  }
}
