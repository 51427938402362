<div class="row searchLine">
  <div class="col-12 text-right">
    <button
      type="button"
      class="btn btn-success cancel"
      (click)="closeView()"
      *ngIf="'view'.indexOf(globalService.adminType) !== -1"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-success submit"
      (click)="editCompetition()"
      *ngIf="'view'.indexOf(globalService.adminType) !== -1"
    >
      Edit
    </button>
  </div>
</div>
<table class="table table-striped m-10" aria-describedby="Competition List">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Start Date</th>
      <th scope="col">End Date</th>
      <th scope="col">Entry End</th>
      <th scope="col" class="text-center w-120px">Max Entries</th>
      <th scope="col" class="text-center w-120px">Max Alt.</th>
    </tr>
  </thead>
  <tbody *ngIf="competition">
    <tr class="data-row">
      <td class="align-middle">
        {{ competition.id === 0 ? "New" : competition.id }}
      </td>
      <td class="align-middle">{{ competition.name }}</td>
      <td class="align-middle">{{ competition.startDate | date }}</td>
      <td class="align-middle">{{ competition.endDate | date }}</td>
      <td class="align-middle">{{ competition.entryEndDate | date }}</td>
      <td class="text-center align-middle w-120px">
        {{ competition.maxEntries | number }}
      </td>
      <td class="text-center align-middle w-120px">
        {{ competition.maxAlternates | number }}
      </td>
    </tr>
  </tbody>
</table>
