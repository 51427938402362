import { Component, OnInit, ViewChild } from '@angular/core';
import { Contestant, ContestantDisplay } from '../../../../models/contestant';
import { ContestantService } from '../../../../services/contestant.service';
import { GlobalService } from '../../../../services/global.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  NgbModal,
  NgbModalModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ViewCompetitionContestantsEditComponent } from '../view-competition-contestants-edit/view-competition-contestants-edit.component';

@Component({
  selector: 'app-view-competition-contestants',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbModalModule,
    NgbPaginationModule,
    RouterModule,
    ViewCompetitionContestantsEditComponent,
  ],
  templateUrl: './view-competition-contestants.component.html',
  styleUrl: './view-competition-contestants.component.scss',
})
export class ViewCompetitionContestantsComponent implements OnInit {
  @ViewChild('adminEditContestants', { static: false })
  private adminEditContestants: any;

  public contestants: Array<Contestant> = [];
  public contestantsFiltered: Array<Contestant> = [];
  public contestantsDisplay: Array<ContestantDisplay> = [];
  public contestantsSelected: Contestant;
  public page = 1;
  public pageSize = 10;
  public contestantsSearch: string;
  public type: string;

  constructor(
    private contestantService: ContestantService,
    public globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.contestantsSearch = '';
    this.getContestants();
  }

  getContestants() {
    this.contestantService
      .getContestantSearch(this.globalService.adminSelectionId, '')
      .subscribe((results) => {
        this.contestants = results;
        this.contestantsFiltered = this.contestants;
        this.refreshContestants();
      });
  }

  onSort(e: any) {
    console.log(e);
  }

  editContestant(id: number) {
    const _contestantsSelected = this.contestants.find((p) => p.id === id);
    this.contestantsSelected = _contestantsSelected
      ? _contestantsSelected
      : this.contestantService.defaultContestant;
    this.type = 'edit';
    this.modalService.open(this.adminEditContestants, {
      centered: true,
      size: 'lg',
      windowClass: 'dark-modal',
    });
  }

  refreshContestants() {
    const startIdx = (this.page - 1) * this.pageSize;
    const endIdx =
      startIdx + this.pageSize > this.contestantsFiltered.length
        ? this.contestantsFiltered.length
        : startIdx + this.pageSize;
    this.contestantsDisplay = [];
    for (let i = startIdx; i < endIdx; i++) {
      const contestant = this.contestantsFiltered[i];
      const contestantsDisplay = new ContestantDisplay(
        contestant.id,
        i + 1,
        contestant.name,
        contestant.email,
        contestant.telephone,
        []
      );
      this.contestantsDisplay.push(contestantsDisplay);
    }
  }

  filterData() {
    this.contestantsFiltered = this.contestants.filter(
      (p) =>
        p.name.toLowerCase().includes(this.contestantsSearch.toLowerCase()) ||
        p.email.toLowerCase().includes(this.contestantsSearch.toLowerCase())
    );
    this.refreshContestants();
  }

  returnOption(e: string) {
    const options = e.split('/');
    switch (options[0]) {
      case 'cancel':
        this.modalService.dismissAll();
        break;
      case 'delete':
        this.modalService.dismissAll();
        const contestantsIdx = this.contestants.findIndex(
          (p) => p.id === Number(options[1])
        );
        this.contestants.splice(contestantsIdx, 1);
        this.filterData();
        this.refreshContestants();
    }
  }

  updateContestant(e: Contestant) {
    this.modalService.dismissAll();
    const contestantsIdx = this.contestants.findIndex((p) => p.id === e.id);
    // if -1 then this was an add
    if (contestantsIdx === -1) {
      this.contestants.push(e);
    } else {
      this.contestants[contestantsIdx] = e;
    }
    this.filterData();
    this.refreshContestants();
  }

  addContestant() {
    this.contestantsSelected = new Contestant(0, '', '', '');
    this.type = 'new';
    this.modalService.open(this.adminEditContestants, {
      centered: true,
      size: 'lg',
      windowClass: 'dark-modal',
    });
  }
}
