<div class="container text-center">
  <div class="row">
    <div class="col-md-12">
      <img
        class="mx-auto d-block w100"
        src="images/gm-logo-new.png"
        alt="GM Logo"
      />
    </div>
  </div>
</div>
