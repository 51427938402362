import { People } from './people';

export class Entries {
  constructor(
    public id: number,
    public competitionId: number,
    public contestantId: number,
    public people: People,
    public isAlternate: boolean
  ) {}
}

export class EntriesCreate {
  constructor(
    public competitionId: number,
    public contestantId: number,
    public people: People,
    public isAlternate: boolean
  ) {}
}

export class EntriesWithAwardsDTO {
  constructor(
    public id: number,
    public competitionId: number,
    public contestantId: number,
    public people: People,
    public isAlternative: boolean,
    public awardPoints: number,
    public awardBonus: number,
    public awardNotes: string
  ) {}
}
export class EntriesDisplay {
  constructor(
    public id: number,
    public idx: number,
    public name: string,
    public age: number,
    public dateOfDeath: Date | null,
    public causeOfDeath: string,
    public points: number,
    public notes: string,
    public bonus: number,
    public isAlternate: boolean,
    public competitionId: number,
    public contestantId: number,
    public people: People
  ) {}
}
