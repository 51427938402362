import { Component, OnInit, ViewChild } from '@angular/core';
import { Prizes, PrizesDisplay } from '../../../../models/prizes';
import { PrizesService } from '../../../../services/prizes.service';
import { GlobalService } from '../../../../services/global.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  NgbModal,
  NgbModalModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ViewCompetitionPrizesEditComponent } from '../view-competition-prizes-edit/view-competition-prizes-edit.component';

@Component({
  selector: 'app-view-competition-prizes',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbModalModule,
    NgbPaginationModule,
    RouterModule,
    ViewCompetitionPrizesEditComponent,
  ],
  templateUrl: './view-competition-prizes.component.html',
  styleUrl: './view-competition-prizes.component.scss',
})
export class ViewCompetitionPrizesComponent implements OnInit {
  @ViewChild('adminEditPrizes', { static: false }) private adminEditPrizes: any;

  public prizes: Array<Prizes> = [];
  public prizesFiltered: Array<Prizes> = [];
  public prizesDisplay: Array<PrizesDisplay> = [];
  public prizesSelected: Prizes;
  public page = 1;
  public pageSize = 10;
  public prizesSearch: string;
  public type: string;

  constructor(
    private prizesService: PrizesService,
    public globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.prizesSearch = '';
    this.getPrizes();
  }

  getPrizes() {
    this.prizesService
      .getPrizesSearch(this.globalService.adminSelectionId, '')
      .subscribe((results) => {
        this.prizes = results;
        this.prizesFiltered = this.prizes;
        this.refreshPrizes();
      });
  }

  refreshPrizes() {
    const startIdx = (this.page - 1) * this.pageSize;
    const endIdx =
      startIdx + this.pageSize > this.prizesFiltered.length
        ? this.prizesFiltered.length
        : startIdx + this.pageSize;
    this.prizesDisplay = [];
    for (let i = startIdx; i < endIdx; i++) {
      const prizes = this.prizesFiltered[i];
      const prizesDisplay = new PrizesDisplay(
        prizes.id,
        i + 1,
        prizes.code,
        prizes.name,
        prizes.description,
        prizes.prize,
        prizes.type,
        prizes.option
      );
      this.prizesDisplay.push(prizesDisplay);
    }
  }

  filterData() {
    this.prizesFiltered = this.prizes.filter(
      (p) =>
        p.name.toLowerCase().includes(this.prizesSearch.toLowerCase()) ||
        p.description.toLowerCase().includes(this.prizesSearch.toLowerCase())
    );
    this.refreshPrizes();
  }

  editPrizes(id: number) {
    const _prizesSelected = this.prizes.find((p) => p.id === id);
    this.prizesSelected = _prizesSelected
      ? _prizesSelected
      : this.prizesService.defaultPrizes;
    this.type = 'edit';
    this.modalService.open(this.adminEditPrizes, {
      centered: true,
      size: 'lg',
      windowClass: 'dark-modal',
    });
  }

  updateType(e: string) {
    // this.globalService.adminType = e;
    // this.setQueryParams();
  }

  onSort(e: any) {
    console.log(e);
  }

  returnOption(e: string) {
    const options = e.split('/');
    switch (options[0]) {
      case 'cancel':
        this.modalService.dismissAll();
        break;
      case 'delete':
        this.modalService.dismissAll();
        const prizesIdx = this.prizes.findIndex(
          (p) => p.id === Number(options[1])
        );
        this.prizes.splice(prizesIdx, 1);
        this.filterData();
        this.refreshPrizes();
    }
  }

  updatePrizes(e: Prizes) {
    this.modalService.dismissAll();
    const prizesIdx = this.prizes.findIndex((p) => p.id === e.id);
    // if -1 then this was an add
    if (prizesIdx === -1) {
      this.prizes.push(e);
    } else {
      this.prizes[prizesIdx] = e;
    }
    this.filterData();
    this.refreshPrizes();
  }

  addPrizes() {
    this.prizesSelected = new Prizes(0, '', '', '', 0, 0, '');
    this.type = 'new';
    this.modalService.open(this.adminEditPrizes, {
      centered: true,
      size: 'lg',
      windowClass: 'dark-modal',
    });
  }
}
