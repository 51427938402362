<div class="row">
  <div class="col-12 text-center">
    <img
      src="images/events/Easter-2025.jpg"
      alt="Easter 2025 Event"
      style="width: 100%; height: auto; max-width: 800px"
    />
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
    If you are interested in booking an appointment for the event please click
    the button below.
  </div>
</div>
<div class="row">
  <div class="col-12 text-center mb10">
    <a
      target="_top"
      style="
        background-color: #333;
        color: white;
        height: 40px;
        text-transform: uppercase;
        font-family: 'Square Market', 'helvetica neue', helvetica, arial,
          sans-serif;
        letter-spacing: 1px;
        line-height: 38px;
        padding: 0 28px;
        border-radius: 3px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        display: inline-block;
      "
      href="https://ghoulishmortals.shop/products/gm-peter-rottentail-portrait-session"
      rel="nofollow"
      >Book your appointment</a
    >
  </div>
</div>
