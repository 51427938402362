import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CarouselModule } from '@coreui/angular';
@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [CarouselModule, CommonModule],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
})
export class HomePageComponent implements OnInit {
  slides: any[] = new Array(5).fill({
    id: -1,
    src: '',
    title: '',
    subtitle: '',
  });

  ngOnInit(): void {
    this.slides[0] = {
      src: 'images/store-content/3x001.jpg',
    };
    this.slides[1] = {
      src: 'images/store-content/3x002.jpg',
    };
    this.slides[2] = {
      src: 'images/store-content/3x003.jpg',
    };
    this.slides[3] = {
      src: 'images/store-content/3x004.jpg',
    };
    this.slides[4] = {
      src: 'images/store-content/3x005.jpg',
    };
  }

  onItemChange($event: any): void {
    console.log('Carousel onItemChange', $event);
  }
}
