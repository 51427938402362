import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import {
  Entries,
  EntriesCreate,
  EntriesWithAwardsDTO,
} from '../models/entries';

@Injectable({
  providedIn: 'root',
})
export class EntriesService {
  private _url: string;
  private _options: any;

  constructor(private http: HttpClient) {
    this._url = `${environment.ghoulPoolApi}entries`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._options = { headers: headers };
  }

  getEntries(
    competitionId: number,
    contestantEmail: string
  ): Observable<Array<EntriesWithAwardsDTO>> {
    const url =
      this._url + '/' + competitionId.toString() + '/' + contestantEmail;
    return this.http.get<Array<EntriesWithAwardsDTO>>(url).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  getEntryById(id: number): Observable<Entries> {
    const url = this._url + '/' + id.toString();
    return this.http.get<Entries>(url).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  create(entry: EntriesCreate): Observable<ArrayBuffer> {
    const url = this._url;
    return this.http.post(url, entry, this._options).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  delete(id: number): Observable<any> {
    const url = this._url + '/' + id.toString();
    return this.http.delete<any>(url, this._options);
  }

  update(id: number, entry: EntriesCreate): Observable<any> {
    const url = this._url + '/' + id.toString();
    return this.http.put<any>(url, entry, this._options);
  }
}
