<div class="checkboxContainer">
  <span>
    <label class="switch" [for]="name">
      <input
        type="checkbox"
        [id]="name"
        [checked]="checked"
        (change)="checkboxClicked()"
      />
      <div class="slider round"></div>
    </label>
  </span>
  <span class="label">
    <label [for]="name">{{ label }}</label>
  </span>
</div>
