import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import {
  CompetitionName,
  Contestant,
  ContestantCreateDTO,
  ContestantDisplay,
  ContestantInfo,
  ContestantUpdateDTO,
} from '../models/contestant';

@Injectable({
  providedIn: 'root',
})
export class ContestantService {
  private _url: string;
  private _options: any;

  constructor(private http: HttpClient) {
    this._url = `${environment.ghoulPoolApi}contestants`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._options = { headers: headers };
  }

  public get defaultContestant(): Contestant {
    return new Contestant(0, '', '', '');
  }

  getContestantByEmail(email: string): Observable<Contestant> {
    const url = this._url + '/' + email;
    return this.http.get<Contestant>(url).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  save(contestant: ContestantCreateDTO): Observable<any> {
    const url = this._url;
    const contestantCreate = new ContestantCreateDTO(
      contestant.name,
      contestant.email,
      contestant.telephone
    );
    return this.http.post(url, contestantCreate, this._options).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  getContestantSearch(
    competitionId: number,
    search: string
  ): Observable<Array<ContestantInfo>> {
    let url = this._url;
    if (competitionId === -1) {
      url += '/search/';
    } else {
      url += '/competition/' + competitionId.toString() + '/';
    }
    url += encodeURIComponent(search === '' ? '<blank>' : search);
    return this.http.get<Array<ContestantInfo>>(url).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  getContestantById(id: number): Observable<Contestant> {
    const url = this._url + '/id';
    return this.http.get<Contestant>(url + '/' + id.toString()).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  getCompetitionsByContestant(id: number): Observable<Array<CompetitionName>> {
    const url = this._url + '/competitions';
    return this.http
      .get<Array<CompetitionName>>(url + '/' + id.toString())
      .pipe(
        catchError((error) => {
          return throwError(() => new Error(error));
        })
      );
  }

  create(
    competitionId: number,
    contestant: ContestantCreateDTO
  ): Observable<any> {
    const url = this._url + '/' + competitionId.toString();
    return this.http.post(url, contestant, this._options);
  }

  update(id: number, contestant: ContestantUpdateDTO): Observable<any> {
    const url = this._url + '/' + id.toString();
    return this.http.put(url, contestant, this._options);
  }

  delete(id: number): Observable<any> {
    const url = this._url + '/' + id.toString();
    return this.http.delete(url, this._options);
  }

  convertInfoToDisplay(c: ContestantInfo): ContestantDisplay {
    return new ContestantDisplay(
      c.id,
      0,
      c.name,
      c.email,
      c.telephone,
      c.competitionList.split('|')
    );
  }
}
