import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Competition } from '../../../../models/competition';
import { Contestant } from '../../../../models/contestant';

@Component({
  selector: 'app-ghoul-pool-view-contestant-info',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './ghoul-pool-view-contestant-info.component.html',
  styleUrl: './ghoul-pool-view-contestant-info.component.scss',
})
export class GhoulPoolViewContestantInfoComponent {
  @Input()
  competition: Competition;

  @Input()
  contestant: Contestant;

  @Input()
  entryCounts: string;

  getContestantEmailLink(email: string) {
    return '/ghoulpoolEntry?email=' + encodeURI(email);
  }
}
