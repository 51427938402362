<div class="row searchLine">
  <div class="col-6 text-left">
    <label for="contestantsSearch">Search : </label>
    <input
      id="contestantsSearch"
      type="text"
      class="contestantsSearch"
      (keyup)="filterData()"
      [(ngModel)]="contestantsSearch"
      placeholder="Contestants search"
    />
  </div>
  <div class="col-4">&nbsp;</div>
  <div class="col-2">
    <button
      type="button"
      class="btn btn-success submit"
      (click)="addContestant()"
    >
      Add Contestants
    </button>
  </div>
</div>
<div class="table-fixed-height">
  <table class="table table-striped" aria-describedby="Contestant Listing">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col" sortable="name" (sort)="onSort($event)">Name</th>
        <th scope="col" sortable="email" (sort)="onSort($event)">Email</th>
        <th scope="col" sortable="phone" (sort)="onSort($event)">Phone</th>
      </tr>
    </thead>
    <tbody *ngIf="contestantsDisplay">
      <tr *ngFor="let contestant of contestantsDisplay" class="onHover">
        <th scope="row" (click)="editContestant(contestant.id)">
          {{ contestant.idx }}
        </th>
        <td (click)="editContestant(contestant.id)">{{ contestant.name }}</td>
        <td (click)="editContestant(contestant.id)">{{ contestant.email }}</td>
        <td (click)="editContestant(contestant.id)">
          {{ contestant.telephone }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="row">
  <div class="d-flex justify-content-between p-2 ml-2">
    <ngb-pagination
      [collectionSize]="contestantsFiltered.length"
      [(page)]="page"
      [pageSize]="pageSize"
      (pageChange)="refreshContestants()"
      [maxSize]="5"
      [rotate]="true"
      [ellipses]="false"
      [boundaryLinks]="true"
    >
    </ngb-pagination>
  </div>
</div>
<ng-template #adminEditContestants let-modal>
  <app-view-competition-contestants-edit
    [contestant]="contestantsSelected"
    [type]="type"
    (returnOption)="returnOption($event)"
    (returnContestant)="updateContestant($event)"
  ></app-view-competition-contestants-edit>
</ng-template>
