import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Competition } from '../../../../models/competition';
import { Contestant } from '../../../../models/contestant';
import {
  EntriesCreate,
  EntriesDisplay,
  EntriesWithAwardsDTO,
} from '../../../../models/entries';

import { CompetitionService } from '../../../../services/competition.service';
import { ContestantService } from '../../../../services/contestant.service';
import { EmailService } from '../../../../services/email.service';
import { EntriesService } from '../../../../services/entries.service';
import { PeopleService } from '../../../../services/people.service';
import {
  People,
  PeopleCreateDTO,
  PeopleSelection,
  PeopleStatus,
} from '../../../../models/people';
import { environment } from '../../../../../environments/environment';
import { GhoulPoolContestantLookupComponent } from '../../components/ghoul-pool-contestant-lookup/ghoul-pool-contestant-lookup.component';
import { GhoulPoolViewContestantInfoComponent } from '../../components/ghoul-pool-view-contestant-info/ghoul-pool-view-contestant-info.component';
import { GhoulPoolPeopleLookupComponent } from '../../components/ghoul-pool-people-lookup/ghoul-pool-people-lookup.component';
import { GhoulPoolViewEntriesComponent } from '../../components/ghoul-pool-view-entries/ghoul-pool-view-entries.component';
import { GhoulPoolEmailEntriesComponent } from '../../components/ghoul-pool-email-entries/ghoul-pool-email-entries.component';
import { GhoulpoolResultsComponent } from '../../../pages/ghoulpool-results/ghoulpool-results.component';

@Component({
  selector: 'app-ghoulpool-entry',
  standalone: true,
  imports: [
    CommonModule,
    GhoulPoolContestantLookupComponent,
    GhoulPoolEmailEntriesComponent,
    GhoulPoolPeopleLookupComponent,
    GhoulpoolResultsComponent,
    GhoulPoolViewContestantInfoComponent,
    GhoulPoolViewEntriesComponent,
    RouterModule,
  ],
  templateUrl: './ghoulpool-entry.component.html',
  styleUrl: './ghoulpool-entry.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class GhoulpoolEntryComponent implements OnInit {
  @ViewChild('duplicateModal', { static: false }) private duplicateModal = null;

  public competition: Competition = new Competition(
    0,
    '',
    '',
    new Date(),
    new Date(),
    new Date(),
    0,
    0
  );
  private todaysDate = new Date();
  public showLookup = true;
  public entryCounts = '';
  public localContestant = new Contestant(0, '', '', '');
  public entries: Array<EntriesDisplay> = [];
  public totalPoints: number = 0;
  public totalPrizes: number = 0;

  private competitionCode: string = '';

  constructor(
    private competitionService: CompetitionService,
    private contestantService: ContestantService,
    private emailService: EmailService,
    private entriesService: EntriesService,
    private modalService: NgbModal,
    private peopleService: PeopleService,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.showLookup = true;

    this.router.queryParams.subscribe((params) => {
      if (params['code']) {
        this.competitionCode = params['code'];
        this.competitionService
          .getCompetitionByCode(this.competitionCode)
          .subscribe((results) => {
            this.competition = results;
            if (params['email']) {
              this.lookupContestant(params['email']);
            }
          });
      } else {
        this.competitionService.getActiveCompetition().subscribe((results) => {
          this.competition = results;
          if (params['email']) {
            this.lookupContestant(params['email']);
          }
        });
      }
    });
  }

  // accept contestant object from component
  returnContestantInfo(e: Contestant) {
    if (e) {
      this.localContestant = e;
      this.showLookup = false;

      // lookup existing entries
      this.lookupEntries(this.localContestant.email);
    }
  }

  lookupContestant(email: string) {
    this.contestantService.getContestantByEmail(email).subscribe((results) => {
      this.localContestant = results;
      this.showLookup = false;

      // lookup existing entries
      this.lookupEntries(this.localContestant.email);
    });
  }

  lookupEntries(email: string) {
    this.entriesService
      .getEntries(this.competition.id, email)
      .subscribe((results) => {
        this.entries = this.updateEntriesResults(results);
        this.entryCounts = this.calcEntryCounts();
      });
  }

  updateEntriesResults(
    entries: Array<EntriesWithAwardsDTO>
  ): Array<EntriesDisplay> {
    const localEntries: Array<EntriesDisplay> = [];
    let idx = 0;
    this.totalPoints = 0;
    this.totalPrizes = 0;
    for (const entry of entries) {
      idx += 1;
      const entrySingle = new EntriesDisplay(
        entry.id,
        idx,
        entry.people ? entry.people.name : '',
        0,
        entry.people ? entry.people.dateOfDeath : new Date('1/1/1970'),
        entry.people ? entry.people.causeOfDeath : '',
        entry.awardPoints,
        entry.awardNotes,
        entry.awardBonus,
        entry.isAlternative,
        entry.competitionId,
        entry.contestantId,
        entry.people
      );

      if (entry.people) {
        entrySingle.age = this.peopleService.calcAge(
          entry.people.dateOfBirth,
          entry.people.dateOfDeath
        );
      }
      localEntries.push(entrySingle);

      this.totalPoints += entry.awardPoints;
      this.totalPrizes += entry.awardBonus;
    }

    return localEntries;
  }

  replace(inputString: string, fromString: string, toString: string): string {
    let returnString = inputString;
    let tempString = '';
    do {
      tempString = returnString;
      returnString = returnString.replace(fromString, toString);
    } while (tempString !== returnString);
    return returnString;
  }

  calcEntryCounts(): string {
    const altEntryLength = this.entries.filter(
      (x) => x.isAlternate === true
    ).length;
    let ret = (this.entries.length - altEntryLength).toString();
    ret +=
      altEntryLength > 0 ? ' + ' + altEntryLength.toString() + ' alt.' : '';
    return ret;
  }

  allowCompetitionEntry() {
    const entryEndDate = new Date(this.competition.entryEndDate);
    return this.competition && this.todaysDate < entryEndDate;
  }

  addPerson(e: PeopleSelection) {
    // if the returned value was a new creation (from wikipedia) then we need to create the record
    // and add it to the entries
    let person: any;
    if (e.id === -1) {
      e.name = this.toTitleCase(e.name);
      person = new PeopleCreateDTO(
        e.name,
        e.name,
        null,
        null,
        '',
        e.wikiURL,
        e.name,
        PeopleStatus.ALIVE,
        null
      );
      this.peopleService.create(person).subscribe((result) => {
        person = result;
        this.addPersonToEntries(person);
        this.emailService.send(
          environment.internalEmail,
          'A contestant of the Ghoul Pool has created a new celebrity',
          'New Celebrity : ' + person.name,
          false
        );
      });
    } else {
      this.peopleService.getPeopleById(e.id).subscribe((result) => {
        person = result;
        this.addPersonToEntries(person);
      });
    }
  }

  addPersonToEntries(p: People) {
    // fro either a new entry or an existing entry, if we have a 'person' then add it to the users
    // list of entries
    if (p) {
      // build entry to add to table
      const entry = new EntriesCreate(
        this.competition.id,
        this.localContestant.id,
        p,
        this.entries.length >= this.competition.maxEntries
      );

      console.log(entry, this.entries.length, this.competition.maxEntries);

      // check for duplicate
      const entryLookup = this.entries.filter((x) => x.people.id === p.id);
      if (entryLookup.length !== 0) {
        this.modalService.open(this.duplicateModal, {
          centered: true,
          size: 'lg',
          windowClass: 'dark-modal',
        });
      } else {
        // add to entries
        this.entriesService.create(entry).subscribe((result) => {
          const entryResult: any = result;
          const age = this.peopleService.calcAge(p.dateOfBirth, p.dateOfDeath);
          const entryDisplay = new EntriesDisplay(
            entryResult.id,
            this.entries.length + 1,
            p.name,
            age,
            p.dateOfDeath,
            p.causeOfDeath,
            0,
            '',
            0,
            entryResult.isAlternate,
            this.competition.id,
            this.localContestant.id,
            p
          );
          this.entries.push(entryDisplay);
        });
      }
    }
  }

  async deleteEntry(i: number) {
    console.log(i.toString());
    this.entriesService.delete(i).subscribe({
      next: (data) => {
        // delete returned successfully... so all data has been
        // taken care of. Refresh the data on the client.
        this.lookupEntries(this.localContestant.email);
      },
      error: (error) => {
        console.error('There was an error!', error);
      },
    });
  }

  statusChange(e: any) {
    this.entries[this.entries.findIndex((x) => x.id === e.id)] = e;
  }

  renumberEntries() {
    for (let i = 0; i < this.entries.length; i++) {
      this.entries[i].idx = i + 1;
    }
  }

  duplicateOK() {
    this.modalService.dismissAll();
  }

  toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
