<div class="modal-header">
  <h4 class="modal-title ml-2" id="modal-basic-title">
    <span *ngIf="type === 'edit'"
      >Edit Contestant Record - {{ contestantLocal.name }}</span
    >
    <span *ngIf="type === 'new'">New Contestant Record</span>
  </h4>
</div>
<div class="modal-body">
  <div class="row firstRow">
    <div class="col-4">
      <label for="editName">Name:</label>
    </div>
    <div class="col-4">
      <label for="editEmail">Email:</label>
    </div>
    <div class="col-4">
      <label for="editTelephone">Telephone:</label>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <input
        type="text"
        id="editName"
        [(ngModel)]="contestantLocal.name"
        class="edit"
      />
    </div>
    <div class="col-4">
      <input
        type="text"
        id="editEmail"
        [(ngModel)]="contestantLocal.email"
        class="edit"
      />
    </div>
    <div class="col-4">
      <input
        type="text"
        id="editTelephone"
        [(ngModel)]="contestantLocal.telephone"
        class="edit"
      />
    </div>
  </div>
</div>
<div class="row footer" *ngIf="!deleting">
  <div class="col-6 text-left">
    <button
      type="button"
      class="btn btn-danger delete"
      (click)="deleting = true"
    >
      Delete
    </button>
  </div>
  <div class="col-6 text-right">
    <button
      type="button"
      class="btn btn-default cancel"
      (click)="cancelDialog()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success submit"
      [disabled]="!changesMade()"
      (click)="updateContestant()"
    >
      <em *ngIf="spinning" class="fas fa-spinner fa-spin"></em>&nbsp;Submit
    </button>
  </div>
</div>
<div class="row footer" *ngIf="deleting">
  <div class="col-5 text-right">Are you sure?</div>
  <div class="col-1">
    <button
      type="button"
      class="btn btn-danger delete"
      (click)="deleteContestant(contestantLocal.id)"
    >
      Yes
    </button>
  </div>
  <div class="col-6 text-left">
    <button
      type="button"
      class="btn btn-default cancel"
      (click)="deleting = false"
    >
      No
    </button>
  </div>
</div>
