import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-easter2024',
  templateUrl: './easter2024.component.html',
  styleUrls: ['./easter2024.component.css'],
})
export class Easter2024Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
