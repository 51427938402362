export enum PeopleStatus {
  ALIVE = 'alive',
  DEAD = 'dead',
  NEW = 'new',
  PENDING = 'pending',
  PENDING_DEAD = 'pending dead',
}
export class People {
  constructor(
    public id: number,
    public name: string,
    public knownAs: string,
    public dateOfBirth: Date | null,
    public dateOfDeath: Date | null,
    public causeOfDeath: string,
    public wikiURL: string,
    public search: string,
    public status: PeopleStatus,
    public statusChangeRequestedBy: string
  ) {}
}

export class PeopleDisplay {
  constructor(
    public id: number,
    public name: string,
    public age: number,
    public knownAs: string,
    public dateOfBirth: Date | null,
    public dateOfDeath: Date | null,
    public causeOfDeath: string,
    public wikiURL: string,
    public search: string,
    public status: PeopleStatus,
    public statusChangeRequestedBy: string
  ) {}
}

export class PeopleCreateDTO {
  constructor(
    public name: string,
    public knownAs: string,
    public dateOfBirth: Date | null,
    public dateOfDeath: Date | null,
    public causeOfDeath: string,
    public wikiURL: string,
    public search: string,
    public status: PeopleStatus,
    public statusChangeRequestedBy: string | null
  ) {}
}

export class PeopleUpdateDTO {
  constructor(
    public name: string,
    public knownAs: string,
    public dateOfBirth: Date | null,
    public dateOfDeath: Date | null,
    public causeOfDeath: string,
    public wikiURL: string,
    public search: string,
    public status: PeopleStatus,
    public statusChangeRequestedBy: string
  ) {}
}

export class PeopleSelection {
  constructor(public id: number, public name: string, public wikiURL: string) {}
}
